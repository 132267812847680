import { extent } from 'd3-array';
import { scaleTime } from 'd3-scale';
import { timeMonth, timeYear } from 'd3-time';
import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';

import { colors } from '../../consts';
import {
  arrangementTimeseriesFieldFormatted,
  countryField,
  gdpField,
  imf3YearConditionalField,
  imf3YearUnconditionalField,
  issuerFieldExtended,
  loanField,
  rfa3YearField,
  rfaMembershipFieldCapacity,
  rfaMembershipField,
  swapFieldCapacity,
  unlimitedField,
  expirationTimeseriesFieldFormatted,
  permanentString,
  issuerSortOrder,
  unknownString,
  issuerAndFacilityField,
  ecbAccessField,
} from '../../DataLoader';

import './styles.scss';
import { formatAs } from '../../utils';

function TooltipChart({ data }) {
  if (!data.filteredRows.length) {
    return (
      <div style={{ height: '145px', position: 'relative' }}>
        <div className="no-agreements">No agreements for current filter</div>
      </div>
    );
  }

  const rowHeight = 7;
  const width = 300;
  const rowPadding = 12;
  const tickHeight = 5;
  const minRectWidth = 4;
  const borderRadius = 3;
  const margin = { l: 30, r: 30 };

  const agreementDates = data.filteredRows
    .map(d => {
      const start = d[arrangementTimeseriesFieldFormatted];
      let end = d[expirationTimeseriesFieldFormatted];
      if (end === permanentString) {
        end = new Date(); // Ensure domain goes at least to end of current year in case of permanent time
      }
      return [start, end];
    })
    .reduce((a, b) => a.concat(b), []);

  const agreementTimeExtentRaw = extent(agreementDates, d => d);

  const agreementTimeYearExtent = [
    timeYear.floor(agreementTimeExtentRaw[0]),
    timeYear.ceil(agreementTimeExtentRaw[1]),
  ];

  const xScale = scaleTime().domain(agreementTimeYearExtent).range([0, width]);

  let nYears = timeYear.every(1).range(...agreementTimeYearExtent).length;

  let scaleTicks = timeYear.every(1).range(...agreementTimeYearExtent);
  let minorScaleTicks = timeYear.every(1).range(...agreementTimeYearExtent);

  let formatFn = formatAs.mdy;

  if (nYears < 3) {
    // One or two years, show ticks at the six month gap
    scaleTicks = timeMonth
      .every(6)
      .range(...agreementTimeYearExtent)
      .concat(agreementTimeYearExtent[1]);
    minorScaleTicks = timeMonth
      .every(3)
      .range(...agreementTimeYearExtent)
      .concat(agreementTimeYearExtent[1]);
  } else if (nYears < 5) {
    scaleTicks = timeYear
      .every(1)
      .range(...agreementTimeYearExtent)
      .concat(agreementTimeYearExtent[1]);
    minorScaleTicks = timeMonth
      .every(6)
      .range(...agreementTimeYearExtent)
      .concat(agreementTimeYearExtent[1]);
    formatFn = formatAs.year;
  } else {
    minorScaleTicks = timeYear
      .every(1)
      .range(...agreementTimeYearExtent)
      .concat(agreementTimeYearExtent[1]);
    formatFn = formatAs.year;
    scaleTicks = xScale.ticks(4);
  }

  // The complicated logic below ensures that the rows are
  // sorted by issuer type, then grouped by issuer making sure
  // the the issuer groups are ordered by earliest date
  const getSortOrderForIssuerFieldAndFacility = issuerType => {
    return Object.entries(
      data.filteredRows
        .filter(row => row[issuerFieldExtended] === issuerType)
        .reduce((a, b) => {
          const facility = b[issuerAndFacilityField];
          const key = facility;
          if (!a[key]) {
            a[key] = b[arrangementTimeseriesFieldFormatted];
          }
          a[key] = Math.min(b[arrangementTimeseriesFieldFormatted], a[key]);
          return a;
        }, {})
    )
      .sort((a, b) => a[1] - b[1])
      .map(d => d[0]);
  };

  const sortOrderForIssuers = {
    'IMF Conditional': getSortOrderForIssuerFieldAndFacility('IMF Conditional'),
    'IMF Unconditional': getSortOrderForIssuerFieldAndFacility('IMF Unconditional'),
    RFA: getSortOrderForIssuerFieldAndFacility('RFA'),
    Swap: getSortOrderForIssuerFieldAndFacility('Swap'),
  };

  const sortedRows = [...data.filteredRows].sort((a, b) => {
    const issuerSortA = issuerSortOrder[a[issuerFieldExtended]];
    const issuerSortB = issuerSortOrder[b[issuerFieldExtended]];
    if (issuerSortA === issuerSortB) {
      const facilitySortA = sortOrderForIssuers[a[issuerFieldExtended]].indexOf(
        a[issuerAndFacilityField]
      );
      const facilitySortB = sortOrderForIssuers[b[issuerFieldExtended]].indexOf(
        b[issuerAndFacilityField]
      );
      if (facilitySortA === facilitySortB) {
        return a[arrangementTimeseriesFieldFormatted] - b[arrangementTimeseriesFieldFormatted];
      }
      return facilitySortA - facilitySortB;
    }
    return issuerSortA - issuerSortB;
  });

  const ticks = scaleTicks.map(tick => ({
    tickString: formatFn(tick),
    x: xScale(tick),
  }));

  const minorTicks = minorScaleTicks.map(tick => ({
    x: xScale(tick),
  }));

  return (
    <div
      className="tooltipGrid"
      style={{ gridTemplateColumns: `minmax(min-content,auto) ${width}px auto` }}
    >
      {sortedRows.map((row, index) => {
        const dateStart = row[arrangementTimeseriesFieldFormatted];
        const isPermanent = row[expirationTimeseriesFieldFormatted] === permanentString;
        const dateEnd = isPermanent
          ? agreementTimeYearExtent[1]
          : row[expirationTimeseriesFieldFormatted];

        const rectWith = Math.max(xScale(dateEnd) - xScale(dateStart), minRectWidth);

        const isUnlimited = row[unlimitedField];

        let loanAmount = '';

        if (row[unlimitedField] === true) {
          loanAmount = 'Unlimited';
        } else if (row[loanField + '_raw'] === unknownString) {
          loanAmount = 'Unknown';
        } else {
          loanAmount = formatAs.millionsString(row[loanField]);
        }

        return (
          <Fragment key={index}>
            <div className="partnerText">{row[issuerAndFacilityField]}</div>
            <svg height={rowHeight + 'px'} width={width + 'px'}>
              <g>
                <line
                  x1={0}
                  y1={rowHeight / 2}
                  x2={width}
                  y2={rowHeight / 2}
                  stroke="#CCC"
                  strokeDasharray="2,2"
                />
                <rect
                  fill={colors[row[issuerFieldExtended]]}
                  height={rowHeight}
                  width={rectWith}
                  x={xScale(dateStart)}
                  y={0}
                  rx={borderRadius}
                  ry={borderRadius}
                />
              </g>
            </svg>
            <div style={{ display: 'flex' }}>
              <div className="amountText">{`${loanAmount}${
                isPermanent ? ' (Permanent)' : ''
              }`}</div>
              {isUnlimited && (
                <svg height={rowHeight + rowPadding + 'px'} width={rowHeight + rowPadding + 'px'}>
                  <text
                    transform={`translate(${(rowHeight + rowPadding) / 2},0)`}
                    style={{
                      fill: '#F5D311',
                      fontSize: '12px',
                      stroke: '#000',
                    }}
                  >
                    ★
                  </text>
                </svg>
              )}
            </div>
          </Fragment>
        );
      })}
      <div></div>
      <svg
        width={width + margin.l + margin.r}
        height={tickHeight + 20}
        style={{ transform: 'translate(-30px, 0)' }}
      >
        {minorTicks.map(tick => {
          return (
            <g key={tick.x} transform={`translate(${margin.l},0)`}>
              <line className="tick" x1={tick.x} x2={tick.x} y1={2} y2={5} />
            </g>
          );
        })}
        {ticks.map(tick => {
          return (
            <g key={tick.tickString} transform={`translate(${margin.l},0)`}>
              <line className="tick" x1={tick.x} x2={tick.x} y1={2} y2={2 + tickHeight} />
              <text className="tick-text" x={tick.x} y={tickHeight + 2}>
                {tick.tickString}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  );
}

function CovidTooltip({ data, eventFrom, name, locked }) {
  const rfaMembership = data[rfaMembershipField];
  return (
    <div className="tooltip countryTooltip">
      <div className="content">
        <div className="tooltipTitle">{name.toUpperCase()}</div>
        {rfaMembership && (
          <div className="content-stat">
            RFA Membership: <span className="stat">{rfaMembership}</span>
          </div>
        )}
        <TooltipChart data={data} />
        <div className="locked">
          {(eventFrom === 'map' || locked) &&
            `${
              locked
                ? 'Click anywhere to close tooltip and reset.'
                : 'Click to lock tooltip and filter country below.'
            }`}
        </div>
      </div>
    </div>
  );
}

export const MemoizedCovidTooltip = React.memo(CovidTooltip);

function LendingCapacityTooltip({ data, name }) {
  const dataForCountry = data.find(row => row[countryField] === name);
  const isUnlimited = dataForCountry[unlimitedField];
  const accessToECB = dataForCountry[ecbAccessField];
  return (
    <div className="tooltip capacityTooltip">
      <div className="content">
        <div className="tooltipTitle">
          {name.toUpperCase()}
        </div>
        <div className="content-stat">
          GDP: {formatAs.millionsString(dataForCountry[gdpField], 3)}
        </div>
        {dataForCountry[rfaMembershipFieldCapacity] && (
          <div className="content-stat">RFA: {dataForCountry[rfaMembershipFieldCapacity]}</div>
        )}
        <div className="accessTitle tooltipTitle">Access Limits</div>
        <div className="content-stat">
          IMF Conditional:{' '}
          <span className="stat">
            {formatAs.millionsString(dataForCountry[imf3YearConditionalField], 3)}
          </span>
        </div>
        <div className="content-stat">
          IMF Unconditional:{' '}
          <span className="stat">
            {formatAs.millionsString(dataForCountry[imf3YearUnconditionalField], 3)}
          </span>
        </div>
        <div className={`content-stat ${dataForCountry[rfa3YearField] ? '' : 'fade-stat'}`}>
          RFA:{' '}
          <span className="stat">{formatAs.millionsString(dataForCountry[rfa3YearField], 3)}</span>
        </div>
        <div
          className={`content-stat ${
            dataForCountry[swapFieldCapacity] || accessToECB ? '' : 'fade-stat'
          }`}
        >
          SWAP:{' '}
          <span className="stat">
            {formatAs.millionsString(dataForCountry[swapFieldCapacity], 3)}
            <span style={{ fontWeight: 'normal' }}>{isUnlimited ? ' (Unlimited) ' : ''}</span>
            {accessToECB && 'Access to ECB Swap'}
          </span>
        </div>
      </div>
    </div>
  );
}

export const MemoizedLendingCapacityTooltip = React.memo(LendingCapacityTooltip);

export default function Tooltip(props) {
  const { children, x, y } = props;

  const tooltipRef = useRef(null);

  const [snapRight, setSnapRight] = useState(false);

  useLayoutEffect(() => {
    if (!tooltipRef.current) {
      return;
    }
    const rect = tooltipRef.current.getBoundingClientRect();
    if (rect.right > window.innerWidth) {
      setSnapRight(true);
    }
  }, [x]);

  const style = {
    top: y + 'px',
  };

  if (snapRight) {
    style.right = '0px';
  } else {
    style.left = x + 'px';
  }

  return (
    <div className="tooltipWrapper" ref={tooltipRef} style={style}>
      {children}
    </div>
  );
}
