import React, { useMemo, useState } from 'react';

import {
  countryField,
  ecbAccessField,
  gdpField,
  imf3YearConditionalField,
  imf3YearUnconditionalField,
  incomeGroupFieldCapacity,
  incomeGroupSortOrder,
  rfa3YearField,
  rfaMembershipFieldCapacity,
  swapFieldCapacity,
  unlimitedField,
} from '../../DataLoader';
import caretDown from '../../images/caret-down.svg';
import tooltipIcon from '../../images/tooltip-icon.svg';
import { formatAs } from '../../utils';

import './styles.scss';

function formatFieldNumeric(data, fieldName) {
  return formatAs.millionsString(data[fieldName], 4);
}

function CapacityRow({ data, handleCapacityCountryHover }) {
  const classNameRightAlign = `right-align`;

  const {
    [ecbAccessField]: accessToECB,
    [countryField]: country,
    [gdpField]: gdp,
    [incomeGroupFieldCapacity]: incomeGroup,
    [unlimitedField]: unlimited,
  } = data;

  const gdpNumeric = gdp ? formatAs.millionsString(gdp, 4) : '';
  const imfConditionalNumeric = formatFieldNumeric(data, imf3YearConditionalField);
  const imfUnconditionalNumeric = formatFieldNumeric(data, imf3YearUnconditionalField);
  const rfa3YearNumeric = formatFieldNumeric(data, rfa3YearField);
  const swapFieldNumeric = formatFieldNumeric(data, swapFieldCapacity);

  return (
    <tr>
      <td className={`country-header`}>
        {country}
        {' '}
        <img
          alt="hover for tooltip icon"
          className="tooltip-hover-icon"
          onMouseMove={e => handleCapacityCountryHover(e, country)}
          onMouseOut={e => handleCapacityCountryHover(e, null)}
          src={tooltipIcon}
        />
      </td>
      <td>{incomeGroup}</td>
      <td className={classNameRightAlign}>{gdpNumeric}</td>
      <td className={classNameRightAlign}>{imfConditionalNumeric}</td>
      <td className={classNameRightAlign}>{imfUnconditionalNumeric}</td>
      <td className={classNameRightAlign}>{rfa3YearNumeric}</td>
      <td>{data[rfaMembershipFieldCapacity]}</td>
      <td className={`${classNameRightAlign} no-border`}>
        {swapFieldNumeric}
        {unlimited && ' (Unlimited)'}
        {accessToECB && ' Access to ECB '}
      </td>
    </tr>
  );
}

const MemoizedCapacityRow = React.memo(CapacityRow);

export default function LendingCapacityTable({
  activeYear,
  dataForYear,
  handleCapacityCountryHover,
}) {
  const [activeSort, setActiveSort] = useState(0);
  const [isAscending, setIsAscending] = useState(false);

  const countrySortDescending = (a, b) => a[countryField].localeCompare(b[countryField]);
  const countrySortAscending = (a, b) => b[countryField].localeCompare(a[countryField]);

  const tableHeaders = useMemo(
    () => [
      {
        key: 'country',
        label: 'Country',
        sortAscending: countrySortAscending,
        sortDescending: countrySortDescending,
      },
      {
        key: 'income-group',
        label: 'Income Group',
        sortAscending: (a, b) => {
          if (a[incomeGroupFieldCapacity] === b[incomeGroupFieldCapacity]) {
            return countrySortDescending(a, b);
          }
          return (
            incomeGroupSortOrder[a[incomeGroupFieldCapacity]] -
            incomeGroupSortOrder[b[incomeGroupFieldCapacity]]
          );
        },
        sortDescending: (a, b) => {
          if (a[incomeGroupFieldCapacity] === b[incomeGroupFieldCapacity]) {
            return countrySortDescending(a, b);
          }
          return (
            incomeGroupSortOrder[b[incomeGroupFieldCapacity]] -
            incomeGroupSortOrder[a[incomeGroupFieldCapacity]]
          );
        },
      },
      {
        key: 'gdp',
        label: 'GDP',
        sortAscending: (a, b) => {
          if (a[gdpField] === b[gdpField]) {
            return countrySortDescending(a, b);
          }
          return a[gdpField] - b[gdpField];
        },
        sortDescending: (a, b) => {
          if (a[gdpField] === b[gdpField]) {
            return countrySortDescending(a, b);
          }
          return b[gdpField] - a[gdpField];
        },
      },
      {
        key: 'imf-conditional',
        label: 'IMF Conditional',
        sortAscending: (a, b) => {
          if (a[imf3YearConditionalField] === b[imf3YearConditionalField]) {
            return countrySortDescending(a, b);
          }
          return a[imf3YearConditionalField] - b[imf3YearConditionalField];
        },
        sortDescending: (a, b) => {
          if (a[imf3YearConditionalField] === b[imf3YearConditionalField]) {
            return countrySortDescending(a, b);
          }
          return b[imf3YearConditionalField] - a[imf3YearConditionalField];
        },
      },
      {
        key: 'imf-unconditional',
        label: 'IMF Unconditional',
        sortAscending: (a, b) => {
          if (a[imf3YearUnconditionalField] === b[imf3YearUnconditionalField]) {
            return countrySortDescending(a, b);
          }
          return a[imf3YearUnconditionalField] - b[imf3YearUnconditionalField];
        },
        sortDescending: (a, b) => {
          if (a[imf3YearUnconditionalField] === b[imf3YearUnconditionalField]) {
            return countrySortDescending(a, b);
          }
          return b[imf3YearUnconditionalField] - a[imf3YearUnconditionalField];
        },
      },
      {
        key: 'rfa-3',
        label: 'RFA',
        sortAscending: (a, b) => {
          if (a[rfa3YearField] === b[rfa3YearField]) {
            return countrySortDescending(a, b);
          }
          return a[rfa3YearField] - b[rfa3YearField];
        },
        sortDescending: (a, b) => {
          if (a[rfa3YearField] === b[rfa3YearField]) {
            return countrySortDescending(a, b);
          }
          return b[rfa3YearField] - a[rfa3YearField];
        },
      },
      {
        key: 'rfa-member',
        label: 'RFA Member',
        sortAscending: (a, b) => {
          if (a[rfaMembershipFieldCapacity] === b[rfaMembershipFieldCapacity]) {
            return countrySortDescending(a, b);
          }
          return b[rfaMembershipFieldCapacity].localeCompare(a[rfaMembershipFieldCapacity]);
        },
        sortDescending: (a, b) => {
          if (a[rfaMembershipFieldCapacity] === b[rfaMembershipFieldCapacity]) {
            return countrySortDescending(a, b);
          }
          return a[rfaMembershipFieldCapacity].localeCompare(b[rfaMembershipFieldCapacity]);
        },
      },
      {
        key: 'swap',
        label: 'Swap',
        sortAscending: (a, b) => {
          if (a[swapFieldCapacity] === b[swapFieldCapacity]) {
            return countrySortDescending(a, b);
          }
          return a[swapFieldCapacity] - b[swapFieldCapacity];
        },
        sortDescending: (a, b) => {
          if (a[swapFieldCapacity] === b[swapFieldCapacity]) {
            return countrySortDescending(a, b);
          }
          return b[swapFieldCapacity] - a[swapFieldCapacity];
        },
      },
    ],
    []
  );

  const handleSetActiveSort = i => {
    if (activeSort === i) {
      setIsAscending(!isAscending);
      return;
    }
    setActiveSort(i);
    setIsAscending(false);
  };

  const sortKey = isAscending ? 'sortAscending' : 'sortDescending';

  const sortedDataForYear = [...(dataForYear || [])].sort(tableHeaders[activeSort][sortKey]);

  return (
    <div className="DataTable CapacityTable">
      <h2>Lending Capacity Table: {activeYear} </h2>
      <div className="table-wrapper">
        <table>
          <tbody>
            <tr>
              {tableHeaders.map((h, i) => (
                <th className="table-header" key={h.label} onClick={() => handleSetActiveSort(i)}>
                  <div className="header-wrapper">
                    {h.label}
                    {activeSort === i && (
                      <img
                        alt="Sort"
                        src={caretDown}
                        style={{ transform: `rotate(${isAscending ? 180 : 0}deg)` }}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
            {(sortedDataForYear || []).map((d, i) => {
              return (
                <MemoizedCapacityRow
                  data={d}
                  handleCapacityCountryHover={handleCapacityCountryHover}
                  key={`${d[countryField]}-${activeYear}`}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
