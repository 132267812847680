import { max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import debounce from 'lodash.debounce';
import React, { useState, useMemo, useRef, useLayoutEffect } from 'react';

import { colors } from '../../consts';
import { filterRowsByIssuer, filterRowsByYear, getIssuerAggregates } from '../../DataLoader';
import { formatAs } from '../../utils';

import './styles.scss';

const xScale = scaleLinear();
const minWidth = 180; // (1.5em ~= 24px * 6.25)

export default function Summary({ activeIssuers, activeYear, dataAll }) {
  const summaryRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [tooltipData, setTooltipData] = useState(null);

  function hoverSection(event, datum) {
    if ('clientX' in event && datum) {
      const x = event.clientX;
      setTooltipData({ datum, x });
    } else {
      setTooltipData(null);
    }
  }

  useLayoutEffect(() => {
    function _measure() {
      setWidth(summaryRef.current.offsetWidth);
    }
    _measure();
    const debouncedMeasure = debounce(_measure, 100, { leading: true, trailing: true });
    window.addEventListener('resize', debouncedMeasure);
    return function cleanup() {
      window.removeEventListener('resize', debouncedMeasure);
    };
  }, []);

  const issuerAggregatesFiltered = useMemo(
    () =>
      getIssuerAggregates(
        dataAll.rows.filter(filterRowsByYear(activeYear)).filter(filterRowsByIssuer(activeIssuers))
      ),
    [activeIssuers, activeYear, dataAll.rows]
  );

  const summaryEls = useMemo(() => {
    const maxValue = max(issuerAggregatesFiltered, d => d[1].value);
    xScale.domain([0, maxValue]);
    return issuerAggregatesFiltered.map(([issuer, { value }]) => {
      const inRange = Boolean(activeIssuers.includes(issuer));
      const sectionWidth = inRange ? xScale(value) * width : 0;
      const overWidth = sectionWidth >= minWidth;
      const valueStringArr = formatAs.millionsString(value).split('');
      const last = valueStringArr.pop();
      let units = '';
      switch (last) {
        case 'M':
          units = 'Million';
          break;
        case 'B':
          units = 'Billion';
          break;
        case 'T':
          units = 'Trillion';
          break;
        default:
          break;
      }
      return (
        <div
          className={`section animated ${inRange ? '' : 'collapsed'}`}
          key={`summary-${issuer}`}
          onMouseMove={e =>
            overWidth
              ? null
              : hoverSection(e, {
                  fill: colors[issuer],
                  issuer,
                  value: valueStringArr.join(''),
                  units,
                })
          }
          onMouseOut={e => (overWidth ? null : hoverSection(e, null))}
          style={{
            width: sectionWidth + 'px',
            background: colors[issuer],
            cursor: overWidth ? 'auto' : 'pointer',
          }}
        >
          {overWidth && (
            <>
              <div className="label">{issuer}</div>
              <div className="value">
                <div className="number">{valueStringArr.join('')}</div>
                <div className="units-wrapper">
                  <div className="units">{units}</div>
                  <div className="units-sign">US$</div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    });
  }, [activeIssuers, issuerAggregatesFiltered, width]);

  return (
    <div className="Summary" ref={summaryRef}>
      {summaryEls}
      {tooltipData === null ? null : (
        <div
          key={tooltipData.datum.issuer}
          className="tooltip section"
          style={{
            background: tooltipData.datum.fill,
            left: Math.min(Math.max(tooltipData.x, 60), window.innerWidth - 80),
          }}
        >
          <div className="label">{tooltipData.datum.issuer}</div>
          <div className="value">
            <div className="number">{tooltipData.datum.value}</div>
            <div className="units-wrapper">
              <div className="units">{tooltipData.datum.units}</div>
              <div className="units-sign"> US$</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
