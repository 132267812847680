import React from 'react';

export default function Introduction(props) {
  const { setShow } = props;

  return (
    <div>
      <div className="content">
        <p>
          The Global Financial Safety Net (GFSN) is a set of institutions and mechanisms that
          provide insurance against crises and financing to mitigate their impacts. It has four main
          layers: countries' own international reserves; bilateral swap arrangements whereby central
          banks exchange currencies to provide liquidity to financial markets; regional financial
          arrangements (RFAs) by which countries pool resources to leverage financing in a crisis;
          and the International Monetary Fund (IMF).
        </p>

        <p>
          The total amount of available crisis finance resources currently stands at more than $3.5
          trillion.
        </p>

        <p>
          The GFSN Tracker is the first global interactive database that measures the annual lending
          capacity of the IMF, central banks and RFAs and the total amount of financing to combat
          the COVID-19 crisis via loans from the IMF, RFAs and currency swaps.
        </p>
      </div>
      <div className="button" onClick={() => setShow(false)}>
        <button>Explore</button>
      </div>
    </div>
  );
}
