import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import React, { useEffect, useRef } from 'react'

import './styles.scss'

export default function Modal(props) {
  const { type, show, setShow, style, children } = props
  const modalRef = useRef()

  useEffect(() => {
    if (modalRef.current) {
      if (show) {
        disableBodyScroll(modalRef.current)
      }
      return clearAllBodyScrollLocks
    }
  }, [show])

  return (
    <div
      ref={modalRef}
      className={`Modal ${show ? 'active' : ''} `}
      onClick={() => setShow(false)}
      style={style}
    >
      <div className={`container ${type}`} onClick={e => e.stopPropagation()}>
        <div className="close" onClick={() => setShow(false)}>
          x
        </div>
        {children}
      </div>
    </div>
  )
}
