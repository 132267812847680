// There are many different ways to determine the centroid of a country
// This list is built from several open data sources including
// https://developers.google.com/public-data/docs/canonical/countries_csv
// In some cases where the data from above is too close to a border
// the centroid has been moved to a clearer visible 'center' of the country.
// Country names are from UN stats https://unstats.un.org/unsd/methodology/m49/

const locations = {
  Afghanistan: { latitude: 33.93911, longitude: 67.709953 },
  Albania: { latitude: 41.153332, longitude: 20.168331 },
  Algeria: { latitude: 28.033886, longitude: 1.659626 },
  'American Samoa': { latitude: -14.270972, longitude: -170.132217 },
  Andorra: { latitude: 42.546245, longitude: 1.601554 },
  Angola: { latitude: -11.202692, longitude: 17.873887 },
  Anguilla: { latitude: 18.220554, longitude: -63.068615 },
  Antarctica: { latitude: -75.250973, longitude: -0.071389 },
  'Antigua and Barbuda': { latitude: 17.060816, longitude: -61.796428 },
  Argentina: { latitude: -38.416097, longitude: -63.616672 },
  Armenia: { latitude: 40.396494, longitude: 44.933705 },
  Aruba: { latitude: 12.52111, longitude: -69.968338 },
  Australia: { latitude: -25.274398, longitude: 133.775136 },
  Austria: { latitude: 47.516231, longitude: 14.550072 },
  Azerbaijan: { latitude: 40.143105, longitude: 47.576927 },
  Bahamas: { latitude: 25.03428, longitude: -77.39628 },
  Bahrain: { latitude: 25.930414, longitude: 50.637772 },
  Bangladesh: { latitude: 23.684994, longitude: 90.356331 },
  Barbados: { latitude: 13.193887, longitude: -59.543198 },
  Belarus: { latitude: 53.709807, longitude: 27.953389 },
  Belgium: { latitude: 50.503887, longitude: 4.469936 },
  Belize: { latitude: 17.189877, longitude: -88.49765 },
  Benin: { latitude: 9.30769, longitude: 2.315834 },
  Bermuda: { latitude: 32.321384, longitude: -64.75737 },
  Bhutan: { latitude: 27.514162, longitude: 90.433601 },
  'Bolivia (Plurinational State of)': { latitude: -16.290154, longitude: -63.588653 },
  'Bosnia and Herzegovina': { latitude: 43.915886, longitude: 17.679076 },
  Botswana: { latitude: -22.328474, longitude: 24.684866 },
  'Bouvet Island': { latitude: -54.423199, longitude: 3.413194 },
  Brazil: { latitude: -14.235004, longitude: -51.92528 },
  'British Indian Ocean Territory': { latitude: -6.343194, longitude: 71.876519 },
  'Brunei Darussalam': { latitude: 4.535277, longitude: 114.727669 },
  Bulgaria: { latitude: 42.733883, longitude: 25.48583 },
  'Burkina Faso': { latitude: 12.238333, longitude: -1.561593 },
  Burundi: { latitude: -3.373056, longitude: 29.918886 },
  'Cabo Verde': { latitude: 16.002082, longitude: -24.013197 },
  Cambodia: { latitude: 12.565679, longitude: 104.990963 },
  Cameroon: { latitude: 7.369722, longitude: 12.354722 },
  Canada: { latitude: 56.130366, longitude: -106.346771 },
  'Central African Republic': { latitude: 6.611111, longitude: 20.939444 },
  Chad: { latitude: 15.454166, longitude: 18.732207 },
  Chile: { latitude: -35.675147, longitude: -71.542969 },
  China: { latitude: 35.86166, longitude: 104.195397 },
  'China, Hong Kong Special Administrative Region': {
    latitude: 22.3193039,
    longitude: 114.1693611,
  },
  'Christmas Island': {
    latitude: -10.447525,
    longitude: 105.690449,
  },
  'Cocos (Keeling) Islands': {
    latitude: -12.164165,
    longitude: 96.870956,
  },
  Colombia: { latitude: 4.570868, longitude: -74.297333 },
  Comoros: { latitude: -11.875001, longitude: 43.872219 },
  Congo: { latitude: -0.228021, longitude: 15.827659 },
  'Cook Islands': { latitude: -21.236736, longitude: -159.777671 },
  'Costa Rica': { latitude: 9.748917, longitude: -83.753428 },
  "Côte d'Ivoire": { latitude: 7.539989, longitude: -5.54708 },
  Croatia: { latitude: 45.1, longitude: 15.2000001 },
  Cuba: { latitude: 21.521757, longitude: -77.781167 },
  Cyprus: { latitude: 35.126413, longitude: 33.429859 },
  Czechia: { latitude: 49.817492, longitude: 15.472962 },
  "Democratic People's Republic of Korea": { latitude: 40.339852, longitude: 127.510093 },
  'Democratic Republic of the Congo': { latitude: -4.038333, longitude: 21.758664 },
  Denmark: { latitude: 56.26392, longitude: 9.501785 },
  Djibouti: { latitude: 11.825138, longitude: 42.590275 },
  Dominica: { latitude: 15.414999, longitude: -61.370976 },
  'Dominican Republic': { latitude: 18.735693, longitude: -70.162651 },
  Ecuador: { latitude: -1.831239, longitude: -78.183406 },
  Egypt: { latitude: 26.820553, longitude: 30.802498 },
  'El Salvador': { latitude: 13.794185, longitude: -88.89653 },
  'Equatorial Guinea': { latitude: 1.650801, longitude: 10.267895 },
  Eritrea: { latitude: 15.179384, longitude: 39.782334 },
  Estonia: { latitude: 58.595272, longitude: 25.0136071 },
  Eswatini: { latitude: -26.522503, longitude: 31.465866 },
  Ethiopia: { latitude: 9.145, longitude: 40.489673 },
  'Falkland Islands (Malvinas)': { latitude: -51.796253, longitude: -59.523613 },
  'Faroe Islands': { latitude: 61.892635, longitude: -6.911806 },
  Fiji: { latitude: -17.713371, longitude: 178.065032 },
  Finland: { latitude: 61.92411, longitude: 25.7481511 },
  France: { latitude: 46.227638, longitude: 2.213749 },
  'French Guiana': { latitude: 3.933889, longitude: -53.125782 },
  'French Polynesia': { latitude: -17.679742, longitude: -149.406843 },
  'French Southern Territories': { latitude: -49.280366, longitude: 69.348557 },
  Gabon: { latitude: -0.803689, longitude: 11.609444 },
  Gambia: { latitude: 13.454698, longitude: -16.57913 },
  Georgia: { latitude: 42.1034929, longitude: 44.0438922 },
  Germany: { latitude: 51.165691, longitude: 10.451526 },
  Ghana: { latitude: 7.946527, longitude: -1.023194 },
  Gibraltar: { latitude: 36.137741, longitude: -5.345374 },
  Greece: { latitude: 39.074208, longitude: 21.824312 },
  Greenland: { latitude: 71.706936, longitude: -42.604303 },
  Grenada: { latitude: 12.1165, longitude: -61.679 },
  Guadeloupe: { latitude: 16.995971, longitude: -62.067641 },
  Guam: { latitude: 13.444304, longitude: 144.793731 },
  Guatemala: { latitude: 15.783471, longitude: -90.230759 },
  Guernsey: { latitude: 49.465691, longitude: -2.585278 },
  Guinea: { latitude: 9.945587, longitude: -9.696645 },
  'Guinea-Bissau': { latitude: 11.803749, longitude: -15.180413 },
  Guyana: { latitude: 4.860416, longitude: -58.93018 },
  Haiti: { latitude: 18.971187, longitude: -72.285215 },
  'Heard Island and McDonald Islands': { latitude: -53.08181, longitude: 73.504158 },
  'Holy See': { latitude: 41.902916, longitude: 12.453389 },
  Honduras: { latitude: 15.199999, longitude: -86.241905 },
  Hungary: { latitude: 47.162494, longitude: 19.5033041 },
  Iceland: { latitude: 64.963051, longitude: -19.020835 },
  India: { latitude: 20.593684, longitude: 78.96288 },
  Indonesia: { latitude: -0.789275, longitude: 113.921327 },
  'Iran (Islamic Republic of)': { latitude: 32.427908, longitude: 53.688046 },
  Iraq: { latitude: 33.223191, longitude: 43.679291 },
  Ireland: { latitude: 53.1423672, longitude: -7.6920536 },
  'Isle of Man': { latitude: 54.236107, longitude: -4.548056 },
  Israel: { latitude: 31.046051, longitude: 34.851612 },
  Italy: { latitude: 41.87194, longitude: 12.56738 },
  Jamaica: { latitude: 18.109581, longitude: -77.297508 },
  Japan: { latitude: 36.204824, longitude: 138.252924 },
  Jersey: { latitude: 49.214439, longitude: -2.13125 },
  Jordan: { latitude: 30.585164, longitude: 36.238414 },
  Kazakhstan: { latitude: 48.019573, longitude: 66.923684 },
  Kenya: { latitude: -0.023559, longitude: 37.906193 },
  Kiribati: { latitude: -3.370417, longitude: -168.734039 },
  Kosovo: { latitude: 42.602636, longitude: 20.902977 },
  Kuwait: { latitude: 29.31166, longitude: 47.481766 },
  Kyrgyzstan: { latitude: 41.20438, longitude: 74.766098 },
  "Lao People's Democratic Republic": { latitude: 19.85627, longitude: 102.495496 },
  Latvia: { latitude: 56.879635, longitude: 24.603189 },
  Lebanon: { latitude: 33.854721, longitude: 35.862285 },
  Lesotho: { latitude: -29.609988, longitude: 28.233608 },
  Liberia: { latitude: 6.428055, longitude: -9.429499 },
  Libya: { latitude: 26.3351, longitude: 17.228331 },
  Liechtenstein: { latitude: 47.166, longitude: 9.555373 },
  Lithuania: { latitude: 55.169438, longitude: 23.881275 },
  Luxembourg: { latitude: 49.815273, longitude: 6.129583 },
  Madagascar: { latitude: -18.766947, longitude: 46.869107 },
  Malawi: { latitude: -13.254308, longitude: 34.301525 },
  Malaysia: { latitude: 4.210484, longitude: 101.975766 },
  Maldives: { latitude: 4.175286, longitude: 73.509114 },
  Mali: { latitude: 17.570692, longitude: -3.996166 },
  Malta: { latitude: 35.937496, longitude: 14.375416 },
  'Marshall Islands': { latitude: 7.131474, longitude: 171.184478 },
  Martinique: { latitude: 14.641528, longitude: -61.024174 },
  Mauritania: { latitude: 21.00789, longitude: -10.940835 },
  Mauritius: { latitude: -20.348404, longitude: 57.552152 },
  Mayotte: { latitude: -12.8275, longitude: 45.166244 },
  Mexico: { latitude: 23.634501, longitude: -102.552784 },
  'Micronesia (Federated States of)': { latitude: 7.425554, longitude: 150.550812 },
  Monaco: { latitude: 43.750298, longitude: 7.412841 },
  Mongolia: { latitude: 46.862496, longitude: 103.846656 },
  Montenegro: { latitude: 42.708678, longitude: 19.37439 },
  Montserrat: { latitude: 16.742498, longitude: -62.187366 },
  Morocco: { latitude: 31.791702, longitude: -7.09262 },
  Mozambique: { latitude: -18.665695, longitude: 35.529562 },
  Myanmar: { latitude: 21.916221, longitude: 95.955974 },
  Namibia: { latitude: -22.95764, longitude: 18.49041 },
  Nauru: { latitude: -0.522778, longitude: 166.931503 },
  Nepal: { latitude: 28.394857, longitude: 84.124008 },
  Netherlands: { latitude: 52.132633, longitude: 5.291266 },
  'New Caledonia': { latitude: -20.904305, longitude: 165.618042 },
  'New Zealand': { latitude: -40.900557, longitude: 174.885971 },
  Nicaragua: { latitude: 12.865416, longitude: -85.207229 },
  Niger: { latitude: 17.607789, longitude: 8.081666 },
  Nigeria: { latitude: 9.081999, longitude: 8.675277 },
  Niue: { latitude: -19.054445, longitude: -169.867233 },
  'North Macedonia': { latitude: 41.608635, longitude: 21.745275 },
  'Northern Mariana Islands': { latitude: 17.33083, longitude: 145.38469 },
  Norway: { latitude: 60.472024, longitude: 8.468946 },
  Oman: { latitude: 21.512583, longitude: 55.923255 },
  Pakistan: { latitude: 30.375321, longitude: 69.345116 },
  Palau: { latitude: 7.51498, longitude: 134.58252 },
  Panama: { latitude: 8.537981, longitude: -80.782127 },
  'Papua New Guinea': { latitude: -6.314993, longitude: 143.95555 },
  Paraguay: { latitude: -23.442503, longitude: -58.443832 },
  Peru: { latitude: -9.189967, longitude: -75.015152 },
  Pitcairn: { latitude: -24.703615, longitude: -127.439308 },
  Philippines: { latitude: 12.879721, longitude: 121.774017 },
  Poland: { latitude: 51.919438, longitude: 19.145136 },
  Portugal: { latitude: 39.399872, longitude: -8.224454 },
  'Puerto Rico': { latitude: 18.220833, longitude: -66.590149 },
  Qatar: { latitude: 25.354826, longitude: 51.183884 },
  'Republic of Korea': { latitude: 35.907757, longitude: 127.766922 },
  'Republic of Moldova': { latitude: 47.411631, longitude: 28.369885 },
  Réunion: { latitude: -21.115141, longitude: 55.536384 },
  Romania: { latitude: 45.943161, longitude: 24.96676 },
  'Russian Federation': { latitude: 61.52401, longitude: 105.318756 },
  Rwanda: { latitude: -1.940278, longitude: 29.873888 },
  'Saint Helena': { latitude: -24.143474, longitude: -10.030696 },
  'Saint Kitts and Nevis': { latitude: 17.357822, longitude: -62.782998 },
  'Saint Lucia': { latitude: 13.909444, longitude: -60.978893 },
  'Saint Pierre and Miquelon': { latitude: 46.941936, longitude: -56.27111 },
  'Saint Vincent and the Grenadines': { latitude: 12.984305, longitude: -61.287228 },
  Samoa: { latitude: -13.759029, longitude: -172.104629 },
  'San Marino': { latitude: 43.94236, longitude: 12.457777 },
  'Sao Tome and Principe': { latitude: 0.18636, longitude: 6.613081 },
  'Saudi Arabia': { latitude: 23.885942, longitude: 45.079162 },
  Senegal: { latitude: 14.497401, longitude: -14.452362 },
  Serbia: { latitude: 44.016521, longitude: 21.005859 },
  Seychelles: { latitude: -4.679574, longitude: 55.491977 },
  'Sierra Leone': { latitude: 8.460555, longitude: -11.779889 },
  Singapore: { latitude: 1.352083, longitude: 103.819836 },
  Slovakia: { latitude: 48.669026, longitude: 19.699024 },
  Slovenia: { latitude: 46.151241, longitude: 14.995463 },
  'Solomon Islands': { latitude: -9.64571, longitude: 160.156194 },
  Somalia: { latitude: 5.152149, longitude: 46.199616 },
  'South Africa': { latitude: -30.559482, longitude: 22.937506 },
  'South Georgia and the South Sandwich Islands': { latitude: -54.429579, longitude: -36.587909 },
  'South Sudan': { latitude: 6.8769919, longitude: 31.3069788 },
  Spain: { latitude: 40.463667, longitude: -3.74922 },
  'Sri Lanka': { latitude: 7.873054, longitude: 80.771797 },
  'State of Palestine': { latitude: 31.952162, longitude: 35.233154 },
  Sudan: { latitude: 12.862807, longitude: 30.217636 },
  Suriname: { latitude: 3.919305, longitude: -56.027783 },
  Sweden: { latitude: 58.134664, longitude: 14.288283 },
  Switzerland: { latitude: 46.818188, longitude: 8.227512 },
  'Syrian Arab Republic': { latitude: 34.802075, longitude: 38.996815 },
  Tajikistan: { latitude: 38.861034, longitude: 71.276093 },
  Thailand: { latitude: 15.870032, longitude: 100.992541 },
  'Timor-Leste': { latitude: -8.874217, longitude: 125.727539 },
  Togo: { latitude: 8.619543, longitude: 0.824782 },
  Tokelau: { latitude: -8.967363, longitude: -171.855881 },
  Tonga: { latitude: -21.178986, longitude: -175.198242 },
  'Trinidad and Tobago': { latitude: 10.691803, longitude: -61.222503 },
  Tunisia: { latitude: 33.886917, longitude: 9.537499 },
  Türkiye: { latitude: 38.963745, longitude: 35.243322 },
  Turkmenistan: { latitude: 38.969719, longitude: 59.556278 },
  'Turks and Caicos Islands': { latitude: 21.694025, longitude: -71.797928 },
  Tuvalu: { latitude: -7.109535, longitude: 177.64933 },
  Uganda: { latitude: 1.373333, longitude: 32.290275 },
  Ukraine: { latitude: 48.379433, longitude: 31.1655799 },
  'United Arab Emirates': { latitude: 23.424076, longitude: 53.847818 },
  'United Kingdom of Great Britain and Northern Ireland': {
    latitude: 55.378051,
    longitude: -3.435973,
  },
  'United Republic of Tanzania': { latitude: -6.369028, longitude: 34.888822 },
  'United States of America': { latitude: 37.09024, longitude: -95.712891 },
  'United States Virgin Islands': { latitude: 18.335765, longitude: -64.896335 },
  Uruguay: { latitude: -32.522779, longitude: -55.765835 },
  Uzbekistan: { latitude: 41.377491, longitude: 64.585262 },
  Vanuatu: { latitude: -15.376706, longitude: 166.959158 },
  'Venezuela (Bolivarian Republic of)': { latitude: 6.42375, longitude: -66.58973 },
  'Viet Nam': { latitude: 14.058324, longitude: 108.277199 },
  'Wallis and Futuna Islands': { latitude: -13.768752, longitude: -177.156097 },
  Yemen: { latitude: 15.552727, longitude: 48.516388 },
  Zambia: { latitude: -13.133897, longitude: 27.849332 },
  Zimbabwe: { latitude: -19.015438, longitude: 29.154857 },
};

export default locations;
