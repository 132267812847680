import React from 'react';

import About from '../About';

import './styles.scss';

const navOptions = {
  view: [
    {
      key: 'lending',
      label: () => <div>GFSN Annual Lending Capacity</div>,
    },
    {
      key: 'covid',
      label: () => <div>COVID-19 Financing Arrangements</div>,
    },
  ],
};

function Selector(props) {
  const { type, selected, update } = props;
  return (
    <div className={`Selector ${type}`}>
      {navOptions[type].map(o => (
        <div
          key={o.key}
          className={`option ${type} ${o.key === selected ? 'active' : ''}`}
          onClick={() => update(o.key)}
        >
          {o.label()}
        </div>
      ))}
    </div>
  );
}

export default function Navigation(props) {
  const { headersRef, setView, view } = props;
  return (
    <div className="Navigation">
      <div className="topLevel">
        <div className="title">
          <h1>Global Financial Safety Net Tracker</h1>
        </div>
        <Selector type="view" selected={view} update={setView} />
        <div className="spacer" />
        <About headersRef={headersRef} selectedPage={view} />
      </div>
    </div>
  );
}
