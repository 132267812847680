import { format } from 'd3-format';
import { timeFormat, timeParse } from 'd3-time-format';

export const formatAs = {
  millionsString: (v, sigFigs = 2) => {
    if (v === null || v === undefined) {
      return '';
    }
    const oneMillion = 1_000_000;
    const oneBillion = 1_000_000_000;
    const oneTrillion = 1_000_000_000_000;
    const value = v * oneMillion;

    const formatFn = format(`.${sigFigs}r`);

    if (value < oneBillion) {
      return '$' + formatFn(value / oneMillion) + 'M';
    } else if (value < oneTrillion) {
      return '$' + formatFn(value / oneBillion) + 'B';
    }
    return '$' + formatFn(value / oneTrillion) + 'T';
  },
  number: format(',d'),
  percent: format('.0%'),
  year: timeFormat('%Y'),
  mdy: timeFormat('%m/%d/%y'),
};

export const uniq = (field, data) => {
  return Object.keys(
    data.reduce((a, b) => {
      const k = b[field];
      if (!k) {
        return a;
      }
      a[k] = true;
      return a;
    }, {})
  );
};

// Will log additional numeric parsing errors to console
const VERBOSE = false;

// Will warn in development environment
export const warnDebug = (...message) => {
  if (process.env.NODE_ENV === 'development') {
    // console.warn(...message);
  }
};

// Will log in development environment
export const logDebug = (...message) => {
  if (process.env.NODE_ENV === 'development') {
    // console.log(...message);
  }
};

export const numericParse = (value, row = '', accessor = '') => {
  if (value === undefined) {
    if (VERBOSE) {
      warnDebug('Unable to parse undefined numeric field. Returning null.', row, accessor);
    }
    return null;
  } else if (value === null) {
    if (VERBOSE) {
      warnDebug('Unable to parse null numeric field. Returning null.', row, accessor);
    }
    return null;
  } else if (value === '') {
    if (VERBOSE) {
      warnDebug('Unable to parse empty field. Returning null.', row, accessor);
    }
    return null;
  }
  let numericValue = parseFloat(value.replace(/,/g, ''));
  if (isNaN(numericValue)) {
    if (VERBOSE) {
      warnDebug('Unable to parse numeric field ', value, ' Returning null.', row, accessor);
    }
    numericValue = null;
  }
  return numericValue;
};

const dateFormatRe = /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/;
const parseFn = timeParse('%m/%d/%Y');
export const parseTime = timeString => {
  const validTimeFormat = dateFormatRe.test(timeString);
  if (validTimeFormat) {
    return parseFn(timeString);
  }
  warnDebug('Unable to parse time stamp', timeString);
  return null;
};


export const lastDateOfQuarter = date => {
  const quarter = Math.floor((date?.getMonth() + 3) / 3);
  const year = date?.getFullYear();
  return new Date(year, quarter * 3, 0);
}