import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { colors } from '../../consts';
import {
  countryField,
  getIssuerDataKey,
  imf3YearConditionalField,
  imf3YearUnconditionalField,
  rfa3YearField,
  swapFieldCapacity,
} from '../../DataLoader';
import { LendingFilters } from '../Filters';
import LendingCapacityTable from '../Tables/LendingCapacityTable';
import Map from '../Map';
import Tooltip, { MemoizedLendingCapacityTooltip } from '../Tooltip';

import './styles.scss';
import { useStore } from '../../state/store';

const initialFilterOptions = [
  { active: true, label: 'IMF (Conditional)', value: 'imf-conditional' },
  { active: true, label: 'IMF (Unconditional)', value: 'imf-unconditional' },
  { active: true, label: 'RFA', value: 'rfa' },
  { active: true, label: 'SWAP', value: 'swap' },
].map(o => ({
  ...o,
  color: colors[o.value],
  dataKey: getIssuerDataKey(o.value),
}));

export default function Lending({ dataAll, lastUpdated, mapSize }) {
  const { years } = dataAll;
  const [filterOptions, setFilterOptions] = useState(initialFilterOptions);
  const [activeYear, setActiveYear] = useState('');

  const dataForYear = dataAll.dataByYear[activeYear];

  const setLendingFilterOptions = useStore(state => state.setLendingFilterOptions);

  useEffect(() => {
    setLendingFilterOptions(filterOptions)
  })

  useEffect(() => {
    if (years.length) {
      setActiveYear([...years].sort((a, b) => b - a)[0]);
    }
  }, [years]);

  const handleFilterToggle = useCallback(
    filterValue => {
      const optionIndex = filterOptions.findIndex(option => option.value === filterValue);
      if (optionIndex === -1) {
        // Will never happen, but to cover all bases...
        return;
      }
      const clonedOption = { ...filterOptions[optionIndex] };
      clonedOption.active = !clonedOption.active;
      const updatedOptions = [...filterOptions];
      updatedOptions[optionIndex] = clonedOption;
      setFilterOptions(updatedOptions);
    },
    [filterOptions, setFilterOptions]
  );

  const lendingWrapperRef = useRef(null);

  const [hoveredCountry, setHoveredCountry] = useState({
    selection: null,
    x: 0,
    y: 0,
  });

  const handleCapacityCountryHover = useMemo(
    () => (event, country) => {
      if (!country) {
        setHoveredCountry({ eventFrom: null, selection: null, x: 0, y: 0 });
        return;
      }
      let selection = null;
      if (country) {
        selection = country;
      }
      let x = 0;
      let y = 0;
      let yOffset = 0;
      if (lendingWrapperRef.current) {
        const rect = lendingWrapperRef.current.getBoundingClientRect();
        yOffset = -rect.top - window.scrollY;
      }
      if ('pageX' in event) {
        x = event.pageX + 10;
        y = event.pageY + yOffset - 115;
      }
      setHoveredCountry({
        selection,
        x,
        y,
      });
    },
    []
  );

  const handleSelectYear = event => {
    setActiveYear(event.target.value);
  };

  const activeIssuers = useMemo(
    () => filterOptions.filter(o => o.active).map(o => o.dataKey),
    [filterOptions]
  );

  // Here we nest the lending data in the same format as the covid agreements
  // There will only be a count of 1 or zero for each country, which reflect
  // whether or not there is data for the issuer type
  const dataByCountry = useMemo(() => {
    return (dataForYear || []).reduce((byCountry, b) => {
      let countTotalAllIssuers = 0;
      const byIssuer = {
        'IMF Conditional': { countTotal: 0, rows: [b] },
        'IMF Unconditional': { countTotal: 0, rows: [b] },
        RFA: { countTotal: 0, rows: [b] },
        Swap: { countTotal: 0, rows: [b] },
      };
      const hasIMFConditional = b[imf3YearConditionalField] !== null;
      if (hasIMFConditional && activeIssuers.includes('IMF Conditional')) {
        countTotalAllIssuers += 1;
        byIssuer['IMF Conditional'].countTotal = 1;
      }
      const hasIMFUnconditional = b[imf3YearUnconditionalField] !== null;
      if (hasIMFUnconditional && activeIssuers.includes('IMF Unconditional')) {
        countTotalAllIssuers += 1;
        byIssuer['IMF Unconditional'].countTotal = 1;
      }
      const hasRFA = b[rfa3YearField] !== null;
      if (hasRFA && activeIssuers.includes('RFA')) {
        countTotalAllIssuers += 1;
        byIssuer['RFA'].countTotal = 1;
      }
      const hasSwap = b[swapFieldCapacity] !== null;
      if (hasSwap && activeIssuers.includes('Swap')) {
        countTotalAllIssuers += 1;
        byIssuer['Swap'].countTotal = 1;
      }

      if (byCountry[b[countryField]]) {
        console.warn(
          'Two rows found for year ',
          activeYear,
          ' and country ',
          b[countryField],
          '. This is unexpected and may lead to errors.'
        );
      }

      byCountry[b[countryField]] = {
        byIssuer,
        countTotalAllIssuers,
        data: b,
        location: b.location,
      };
      return byCountry;
    }, {});
  }, [activeIssuers, activeYear, dataForYear]);

  return (
    <div className="Lending" ref={lendingWrapperRef}>
      <Map
        data={dataByCountry}
        height={mapSize.height}
        handleCountryHover={handleCapacityCountryHover}
        handleLockCountry={() => {}}
        lastUpdated={lastUpdated}
        width={mapSize.width}
      />
      <LendingFilters filterOptions={filterOptions} handleFilterToggle={handleFilterToggle}>
        <select onChange={handleSelectYear} value={activeYear}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </LendingFilters>
      <LendingCapacityTable
        activeYear={activeYear}
        dataForYear={dataForYear || []}
        handleCapacityCountryHover={handleCapacityCountryHover}
      />
      {hoveredCountry.selection && (
        <Tooltip x={hoveredCountry.x} y={hoveredCountry.y}>
          <MemoizedLendingCapacityTooltip
            data={dataForYear || []}
            name={hoveredCountry.selection}
          ></MemoizedLendingCapacityTooltip>
        </Tooltip>
      )}
    </div>
  );
}
