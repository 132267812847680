import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ALL_YEARS, colors } from '../../consts';
import {
  getIssuerDataKey,
  filterRowsByIssuer,
  formatIssuerMetadata,
  filterRowsByYear,
  issuerAndFacilityField,
  issuerFieldExtended,
  ecbAccessField
} from '../../DataLoader';
import CovidAgreementsTable from '../Tables/CovidAgreementsTable';
import AgreementsTimeseries from '../AgreementsTimeseries';
import { CovidFilters } from '../Filters';
import Map from '../Map';
import Tooltip, { MemoizedCovidTooltip } from '../Tooltip';

import './styles.scss';

const initialFilterOptions = [
  { active: true, label: 'IMF (Conditional)', value: 'imf-conditional' },
  { active: true, label: 'IMF (Unconditional)', value: 'imf-unconditional' },
  { active: true, label: 'RFA', value: 'rfa' },
  { active: true, label: 'SWAP', value: 'swap' },
].map(o => ({
  ...o,
  color: colors[o.value],
  dataKey: getIssuerDataKey(o.value),
}));

export default function Covid({ dataAll, lastUpdated, mapSize }) {
  const [rowsByCountry, setRowsByCountry] = useState({});
  const [filterOptions, setFilterOptions] = useState(initialFilterOptions);

  const [activeYear, setActiveYear] = useState(ALL_YEARS);

  const handleSelectYear = event => {
    if (event.target.value === ALL_YEARS) {
      setActiveYear(event.target.value);
    } else {
      setActiveYear(+event.target.value);
    }
  };

  // Updates the filter slider state on toggle
  const handleFilterToggle = useCallback(
    filterValue => {
      const optionIndex = filterOptions.findIndex(option => option.value === filterValue);
      if (optionIndex === -1) {
        // Will never happen, but to cover all bases...
        return;
      }
      const clonedOption = { ...filterOptions[optionIndex] };
      clonedOption.active = !clonedOption.active;
      const updatedOptions = [...filterOptions];
      updatedOptions[optionIndex] = clonedOption;
      setFilterOptions(updatedOptions);
    },
    [filterOptions, setFilterOptions]
  );

  const activeIssuers = useMemo(
    () => filterOptions.filter(o => o.active).map(o => o.dataKey),
    [filterOptions]
  );

  // As issuer types are updated and the year is updated, update the data to reflect filters
  useEffect(() => {
    const filteredData = Object.fromEntries(
      Object.entries(dataAll.rowsByCountry).map(([country, countryDatum]) => {
        const filteredRows = countryDatum.rows
          .filter(filterRowsByYear(activeYear))
          .filter(filterRowsByIssuer(activeIssuers));

        return [
          country,
          {
            ...countryDatum,
            byIssuer: formatIssuerMetadata(filteredRows),
            countTotalAllIssuers: filteredRows.length,
            filteredRows,
          },
        ];
      })
    );
    setRowsByCountry(filteredData);
  }, [activeIssuers, activeYear, dataAll]);

  
  const mapRowsByCountry = useMemo(() => {
    return Object.fromEntries(
      Object.entries(rowsByCountry).map(([country, countryDatum]) => {
        const swapIssuers = {};

        // This nasty bit of logic merges swaps by country which we use to plot
        // swaps on the map and not double count them.
        const filteredRowsMergedSwaps = countryDatum.filteredRows.filter(row => {
          if (row[issuerFieldExtended] === 'Swap') {
            if (!swapIssuers[row[issuerAndFacilityField]]) {
              swapIssuers[row[issuerAndFacilityField]] = true;
              return true;
            }
            return false;
          }
          return true;
        });

        const ecb = countryDatum.rows?.[0]?.[ecbAccessField] || false;

        return [
          country,
          {
            ...countryDatum,
            byIssuer: formatIssuerMetadata(filteredRowsMergedSwaps),
            filteredRows: filteredRowsMergedSwaps,
            countTotalAllIssuers: filteredRowsMergedSwaps.length,
            data: {
              [ecbAccessField]: ecb
            }
          },
        ];
      })
    );
  }, [rowsByCountry]);

  const [hoveredCountry, setHoveredCountry] = useState({
    eventFrom: null,
    selection: null,
    x: 0,
    y: 0,
  });
  const [lockedCountry, setLockedCountry] = useState({
    eventFrom: null,
    selection: null,
    x: 0,
    y: 0,
  });

  const covidWrapperRef = useRef(null);

  const handleCountryInteraction = useMemo(
    () =>
      (event, country, eventFrom = null, cb) => {
        if (!country) {
          cb({ eventFrom: null, selection: null, x: 0, y: 0 });
          return;
        }
        let selection = null;
        if (country) {
          selection = country;
        }
        let x = 0;
        let y = 0;
        let yOffset = 0;
        if (covidWrapperRef.current) {
          const rect = covidWrapperRef.current.getBoundingClientRect();
          yOffset = -rect.top - window.scrollY;
        }
        if ('pageX' in event) {
          x = event.pageX + 10;
          y = event.pageY + yOffset - 115;
        }
        cb({
          eventFrom,
          selection,
          x,
          y,
        });
      },
    []
  );

  const handleCountryHover = useMemo(
    () => (event, country, eventFrom) => {
      handleCountryInteraction(event, country, eventFrom, setHoveredCountry);
    },
    [handleCountryInteraction, setHoveredCountry]
  );

  const handleLockCountry = useMemo(
    () => (event, country) => {
      event.stopPropagation();
      if (country && lockedCountry.selection && country === lockedCountry.selection) {
        handleCountryInteraction(event, null, null, setLockedCountry);
        handleCountryInteraction(event, null, null, setHoveredCountry);
        return;
      }
      handleCountryInteraction(event, country, null, setLockedCountry);
    },
    [handleCountryInteraction, lockedCountry, setLockedCountry]
  );

  let hoveredOrLockedData = null;
  if (lockedCountry.selection) {
    hoveredOrLockedData = lockedCountry;
  } else if (hoveredCountry.selection) {
    hoveredOrLockedData = hoveredCountry;
  }

  const additionalTooltipNeeded =
    lockedCountry.selection && hoveredCountry.selection && hoveredCountry.eventFrom !== 'map';

  return (
    <div className="Covid" ref={covidWrapperRef}>
      <Map
        data={mapRowsByCountry}
        height={mapSize.height}
        handleCountryHover={handleCountryHover}
        handleLockCountry={handleLockCountry}
        lastUpdated={lastUpdated}
        width={mapSize.width}
      />
      <CovidFilters filterOptions={filterOptions} handleFilterToggle={handleFilterToggle}>
        <select onChange={handleSelectYear} value={activeYear}>
          <option key="All years" value="All years">
            All years
          </option>
          {dataAll.arrangementYears.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </CovidFilters>
      <AgreementsTimeseries activeYear={activeYear} rowsByCountry={rowsByCountry} />
      <CovidAgreementsTable
        activeIssuers={activeIssuers}
        activeYear={activeYear}
        dataAll={dataAll}
        handleCountryHover={handleCountryHover}
        lockedCountry={lockedCountry.selection}
        rowsByCountry={rowsByCountry}
      />
      {hoveredOrLockedData && (
        <Tooltip x={hoveredOrLockedData.x} y={hoveredOrLockedData.y}>
          <MemoizedCovidTooltip
            data={rowsByCountry[hoveredOrLockedData.selection]}
            eventFrom={hoveredOrLockedData.eventFrom}
            name={hoveredOrLockedData.selection}
            locked={hoveredOrLockedData === lockedCountry}
          ></MemoizedCovidTooltip>
        </Tooltip>
      )}
      {additionalTooltipNeeded && (
        <Tooltip x={hoveredCountry.x} y={hoveredCountry.y}>
          <MemoizedCovidTooltip
            data={rowsByCountry[hoveredCountry.selection]}
            eventFrom={hoveredCountry.eventFrom}
            name={hoveredCountry.selection}
          ></MemoizedCovidTooltip>
        </Tooltip>
      )}
    </div>
  );
}
