import { geoPath, geoMercator } from 'd3-geo';
import { getMatrixTransform, multiplyMatrixAndPoint } from './matrixUtils.js';

const modifiers = { width: 1.15, height: 0.9 };
const rotation = { x: -30, z: 50 };
const combinedMatrix = getMatrixTransform(rotation.x, rotation.z);

const maxGeometry = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-170, 60], // top left
        [190, 60], // top right
        [190, -58.49227331726442], // bottom right
        [-170, -58.49227331726442], // bottom left
      ],
    ],
  },
}; // approx continent bounds excluding the artic

function getBounds(path, geometry) {
  const maxBounds = path.bounds(maxGeometry);
  const bounds = path.bounds(geometry),
    width = bounds[1][0] - bounds[0][0],
    y1 = Math.max(bounds[0][1], maxBounds[0][1]),
    height = bounds[1][1] - y1,
    x = (bounds[0][0] + bounds[1][0]) / 2,
    y = (y1 + bounds[1][1]) / 2;
  return { width, height, x, y };
}

export const rotationMatrix = [
  [combinedMatrix[0][0], combinedMatrix[0][1]],
  [combinedMatrix[1][0], combinedMatrix[1][1]],
  [combinedMatrix[2][0], combinedMatrix[2][1]],
];

export function scaleProjection(geometry, width, height) {
  const projection = geoMercator().scale(1).rotate([-11, 0]).translate([0, 0]);
  const path = geoPath().projection(projection);
  const target = getBounds(path, geometry);

  const s =
    1 /
    Math.max((target.width / width) * modifiers.width, (target.height / height) * modifiers.height);
  const t = [(width - s * target.x) / 2, (height - s * target.y) / 2];

  projection.scale(s).translate(t);
  path.projection(projection);
  return { path, projection };
}

export function coordinateToScreen(coordinate, width, height) {
  const [x, y] = coordinate;
  const input = [x - width / 2, y - height / 2];
  const point = multiplyMatrixAndPoint(combinedMatrix, input);
  const screen = [point[0] + width / 2, point[1] + height / 2];
  return screen;
}

export function fitTransform(path, geometry, width, height, scaleSettings) {
  let target = geometry;

  const bounds = getBounds(path, target);

  const baseScale = 0.9;
  const scale =
    Math.min(
      Math.max(
        baseScale /
          Math.max(
            (bounds.width / width) * modifiers.width,
            (bounds.height / height) * modifiers.height
          ),
        scaleSettings.min
      ),
      scaleSettings.max
    ) * 0.93;

  const screen = coordinateToScreen([bounds.x, bounds.y], width, height);

  const x = width / 1.75 - screen[0] * scale;
  const y = height / 2 - screen[1] * scale;

  return { translation: { x, y }, scale };
}
