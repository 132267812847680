const IMFConditionalColor = '#287E26';
const IMFUnconditionalColor = '#29CB77';
const RFAColor = '#FF0000';
const SwapColor = '#0094C1';

const colors = {
  'imf-conditional': IMFConditionalColor,
  'IMF Conditional': IMFConditionalColor,
  'imf-unconditional': IMFUnconditionalColor,
  'IMF Unconditional': IMFUnconditionalColor,
  rfa: RFAColor,
  RFA: RFAColor,
  swap: SwapColor,
  Swap: SwapColor,
};

const ALL_YEARS = 'All years';

export { ALL_YEARS, colors };
