import { line, curveLinearClosed } from 'd3-shape';
import memoizeOne from 'memoize-one';
import { useMemo, useState } from 'react';
import { MapInteraction } from 'react-map-interaction';
import Ripples from 'react-ripples';
import { feature, merge } from 'topojson';

import globe from '../../images/globe.svg';
import { colors } from '../../consts';
import { ecbAccessField, unlimitedField } from '../../DataLoader';
import countries from './countries_50m.json';
import {
  coordinateToScreen,
  fitTransform,
  rotationMatrix,
  scaleProjection,
} from './projectionUtils.js';
import SwapIcon from './SwapIcon';

import './styles.scss';
import { useStore } from '../../state/store';

const countryFeatures = feature(countries, countries.objects.countries);

const memoizedScaleProjection = memoizeOne(scaleProjection);

const memoizedFitTransform = memoizeOne(fitTransform);

const settings = {
  scale: {
    min: 1,
    max: 21,
  },
  barSize: 8,
};

const isoBarToPath = line()
  .x(d => d[0])
  .y(d => d[1])
  .curve(curveLinearClosed);

const polarToCartesian = ([r, theta]) => [r * Math.cos(theta), r * Math.sin(theta)];

const pointAtVector = (p, r, theta) => {
  const [x0, y0] = polarToCartesian([r, theta]);
  return [p[0] + x0, p[1] + y0];
};

function generateIsoBar(width, height, yOffset, defaultHeight) {
  const pos = {
    x: 0,
    y: -yOffset + width,
  };

  // Generate isometric stack points based on the height required
  const bottomPoint = [pos.x, pos.y];
  const bottomLeft = pointAtVector(bottomPoint, width, Math.PI + (Math.PI * 1) / 6);
  const bottomRight = pointAtVector(bottomPoint, width, -(Math.PI * 1) / 6);
  const topLeft = [bottomLeft[0], bottomLeft[1] - height];
  const topRight = [bottomRight[0], bottomRight[1] - height];
  const middle = [bottomPoint[0], bottomPoint[1] - height];
  const topPoint = [middle[0], middle[1] - width];

  // Will use this to add shadows on the left of the stack
  const leftRect = [bottomPoint, bottomLeft, topLeft, middle, bottomPoint];

  // Will use this to add a "cap" to the stack
  const topRect = [middle, topLeft, topPoint, topRight, middle];

  // Will use this to generate the visible vertices of the stack
  const outline = [bottomPoint, bottomLeft, topLeft, middle, topRight, bottomRight, bottomPoint];

  // Adding a shadow that looks right requires a little extra work. We set the shadow
  // based on a default stack so it's a little wider than it otherwise would be
  const bottomRightSkew = [bottomRight[0], bottomRight[1] - defaultHeight];
  const shadow = [
    bottomLeft,
    pointAtVector(bottomLeft, height, Math.PI + (Math.PI * 1) / 6),
    pointAtVector(bottomRightSkew, height + defaultHeight * 3, Math.PI + (Math.PI * 1) / 6),
    bottomRightSkew,
    bottomPoint,
  ];
  return {
    shadow: isoBarToPath(shadow),
    leftRect: isoBarToPath(leftRect),
    outline: isoBarToPath(outline),
    topRect: isoBarToPath(topRect),
  };
}

export default function Map(props) {
  const { data, height, handleCountryHover, handleLockCountry, lastUpdated, width } = props;

  const transition = false;

  // The reason we choose memoized-one over useMemo is that it will keep values between
  // mounts and unmounts so that the map renders faster between navigation
  const projection = memoizedScaleProjection(countryFeatures, width, height, settings.scale);
  const defaultTransform = memoizedFitTransform(
    projection.path,
    countryFeatures,
    width,
    height,
    settings.scale
  );

  const [transform, setTransform] = useState(defaultTransform);

  const isDefaultTransform = useMemo(
    () => JSON.stringify(defaultTransform) === JSON.stringify(transform),
    [defaultTransform, transform]
  );

  const resetMapTransition = useMemo(
    () =>
      function () {
        setTransform(defaultTransform);
      },
    [defaultTransform, setTransform]
  );

  const zoom = delta => {
    const scale = Math.max(1, Math.round(transform.scale + delta));
    const { x, y } = transform.translation;

    const scaleOffset0 = {
      x: (width * transform.scale - width) / 2,
      y: (height * transform.scale - height) / 2,
    };

    const translation0 = {
      x: (x + scaleOffset0.x) / transform.scale,
      y: (y + scaleOffset0.y) / transform.scale,
    };

    const scaleOffset = {
      x: (width - width * scale) / 2,
      y: (height - height * scale) / 2,
    };

    const translation = {
      x: translation0.x * scale + scaleOffset.x,
      y: translation0.y * scale + scaleOffset.y,
    };

    setTransform({ scale, translation });
  };

  const maxCount = useMemo(
    () =>
      Object.values(data)
        .map(countryDatum => countryDatum.countTotalAllIssuers)
        .reduce((a, b) => Math.max(a, b), 0),
    [data]
  );

  const targetAgreementSize = 4;
  const agreementSize =
    targetAgreementSize * maxCount > height / 5
      ? Math.max(3, height / 5 / maxCount)
      : targetAgreementSize;

  const stacks = useMemo(() => {
    return Object.entries(data)
      .filter(([_, countryDatum]) => {
        // Some entities i.e. ECB do not have location data
        return countryDatum.location;
      })
      .map(([country, countryDatum]) => {
        let yOffset = 0;
        const filteredStacks = Object.entries(countryDatum.byIssuer).filter(
          ([_, issuerDatum]) => issuerDatum.countTotal
        );
        const stackShadow = countryDatum.countTotalAllIssuers
          ? generateIsoBar(
              settings.barSize,
              countryDatum.countTotalAllIssuers * agreementSize,
              0,
              agreementSize
            )
          : null;

        const stackPaths = filteredStacks
          .map(([issuer, issuerDatum], issuerIndex) => {
            const layers = [];
            const lastIssuer = issuerIndex === filteredStacks.length - 1;
            const sortedRows = [...issuerDatum.rows].sort(
              (a, b) => a[unlimitedField] - b[unlimitedField]
            );

            sortedRows.forEach((row, i) => {
              const isUnlimitedSwap = issuer === 'Swap' ? row[unlimitedField] : false;
              const currYOffset = yOffset;
              const cap = lastIssuer && i === issuerDatum.countTotal - 1;
              const bar = generateIsoBar(settings.barSize, agreementSize, yOffset);
              yOffset += agreementSize;
              layers.push({
                count: 1,
                fill: colors[issuer],
                key: `${country}-${issuer}-${issuerDatum.countTotal}-outline-${i}`,
                opacity: 1,
                path: bar.outline,
                stroke: '#000',
                type: 'outlineRect',
                yOffset: currYOffset,
              });
              layers.push({
                count: 1,
                fill: '#000',
                key: `${country}-${issuer}-${issuerDatum.countTotal}-left-rect-${i}`,
                opacity: 0.2,
                path: bar.leftRect,
                stroke: 'none',
                type: 'leftRect',
                yOffset: currYOffset,
              });
              if (cap) {
                layers.push({
                  count: 1,
                  fill: colors[issuer],
                  key: `${country}-${issuer}-${issuerDatum.countTotal}-top-${i}`,
                  opacity: 1,
                  path: bar.topRect,
                  stroke: '#000',
                  type: 'topRect',
                  yOffset: currYOffset,
                });
              }
              if (isUnlimitedSwap) {
                layers.push({
                  key: `${country}-${issuer}-${issuerDatum.countTotal}-swap-icon-${i}`,
                  type: 'swapIcon',
                  yOffset: currYOffset + agreementSize,
                });
              }
              
              if (row[ecbAccessField]) {
                layers.push({
                  key: `${country}-${issuer}-${issuerDatum.countTotal}-euro-icon-${i}`,
                  type: 'euroIcon',
                  yOffset: currYOffset + agreementSize,
                });
              }
              
            });
            return layers;
          })
          .reduce((a, b) => a.concat(b), []);
        const transform = projection.projection([
          countryDatum.location.longitude,
          countryDatum.location.latitude,
        ]);
        return {
          country,
          screen: coordinateToScreen(transform, width, height),
          stackPaths,
          stackShadow,
          transform,
          ecb: countryDatum?.data?.[ecbAccessField],
        };
      })
      .sort((a, b) => a.screen[1] - b.screen[1]);
  }, [agreementSize, data, height, projection, width]);

  return (
    <div
      className="sizeContainer"
      onClick={e => handleLockCountry(e, null)}
      style={{ width, height, pointerEvents: transition ? 'none' : 'auto' }}
    >
      <div className="lastUpdated">Data last updated {lastUpdated}</div>
      <div className="controls">
        <div className="rippleButton">
          <Ripples>
            <button
              type="button"
              disabled={isDefaultTransform}
              className={`${isDefaultTransform ? 'inactive' : ''}`}
              style={{ width: '30px', paddingTop: '5px' }}
              onClick={resetMapTransition}
            >
              <img src={globe} alt="Reset" />
            </button>
          </Ripples>
        </div>
        <div className="rippleButton">
          <Ripples>
            <button
              type="button"
              disabled={transform.scale >= settings.scale.max}
              className={`${transform.scale >= settings.scale.max ? 'inactive' : ''}`}
              style={{ width: '30px', paddingTop: '5px' }}
              onClick={() => zoom(1)}
            >
              +
            </button>
          </Ripples>
        </div>
        <div className="rippleButton">
          <Ripples>
            <button
              type="button"
              disabled={transform.scale <= settings.scale.min}
              className={`${transform.scale <= settings.scale.min ? 'inactive' : ''}`}
              onClick={() => zoom(-1)}
            >
              -
            </button>
          </Ripples>
        </div>
      </div>
      <MapInteraction
        showControls={false}
        disableZoom={true}
        controlsClass="controls"
        minScale={settings.scale.min}
        maxScale={settings.scale.max}
        onChange={setTransform}
        value={transform}
      >
        {({ translation, scale }) => {
          const { x, y } = translation;
          return (
            <div
              className={`mapContainer ${transition ? 'transition' : ''}`}
              onDoubleClick={e => {
                const { target } = e;
                const { id } = target;
                if (id === 'Map') {
                  resetMapTransition();
                }
              }}
            >
              <svg key="Map" id="Map" className="Map" width={props.width} height={props.height}>
                <defs>
                  <linearGradient key="shadow" id="shadow" x1="0%" x2="0%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(0, 0, 0, 0)" />
                    <stop offset="100%" stopColor="rgba(0, 0, 0, 0.35)" />
                  </linearGradient>
                </defs>
                <g
                  key="animated"
                  className="transformContainer"
                  style={{ transform: `translate(${x}px, ${y}px) scale(${scale})` }}
                >
                  <MapRenderer
                    width={width}
                    handleCountryHover={handleCountryHover}
                    height={height}
                    projection={projection}
                    scale={scale}
                    handleLockCountry={handleLockCountry}
                    stacks={stacks}
                  />
                </g>
              </svg>
            </div>
          );
        }}
      </MapInteraction>
    </div>
  );
}

function MapRenderer(props) {
  const { handleCountryHover, handleLockCountry, height, projection, scale, stacks, width } = props;

  const lendingFilterOptions = useStore(state => state.lendingFilterOptions);

  const paths = useMemo(
    () => ({
      countries: countryFeatures.features.map(c => {
        c.d = projection.path(c);
        return c;
      }),
      outline: projection.path(merge(countries, countries.objects.countries.geometries)),
    }),
    [projection]
  );

  const pathEls = useMemo(
    () =>
      paths.countries.map(c => (
        <path
          className={`country country-${c.properties.id}`}
          key={c.properties.id}
          d={c.d}
          stroke="#ADA49E"
          fill={stacks.find(s => s.country === c.properties.name)?.ecb ? '#ffffff' : '#ffffff'} //yellow was #f4cf3d
        />
      )),
    [paths, stacks]
  );

  const shadowEls = useMemo(
    () =>
      stacks.map(stack => {
        return (
          <g
            key={`${stack.country}-shadow`}
            transform={`translate(${stack.screen}) scale(${1 / scale})`}
          >
            <path
              className="shadow"
              d={stack.stackShadow ? stack.stackShadow.shadow : ''}
              fill="url(#shadow)"
            />
          </g>
        );
      }),
    [scale, stacks]
  );

  const stacksX = (scale / settings.scale.max) * 0.095;
  const stacksY = (scale / settings.scale.max) * -0.68;

  const isSwap = useMemo(() => {
    return lendingFilterOptions.find(o => o.value === 'swap')?.active;
  }, [lendingFilterOptions]);

  const stackedPaths = (paths, isSwap) => {

    let isEuroIcon = false;
    for(let i = 0; i < paths.length; i++) {
      if(paths[i].type === 'euroIcon') {
        isEuroIcon = true;
        break;
      }
    }

    let lastYOffset = paths[paths.length - 1]?.yOffset;

    return (
      <>
        {paths.map(g =>
          g.type === 'swapIcon' ? (
            <SwapIcon key={g.key} width={settings.barSize * 1.2} yOffset={g.yOffset} />
          ) : (
            <path
              d={g.path}
              data-stack={g.key}
              fill={g.fill}
              key={g.key}
              opacity={g.opacity}
              stroke={g.stroke}
            />
          )
        )}
        {isSwap && isEuroIcon && <EuroStack width={settings.barSize * 1.2} yOffset={lastYOffset} />}
      </>
    );
  };

  const stackedEls = useMemo(() => {
    return stacks.map(s => (
      <g
        key={s.country}
        className="stack"
        onClick={e => handleLockCountry(e, s.country)}
        onMouseMove={e => handleCountryHover(e, s.country, 'map')}
        onMouseOut={e => handleCountryHover(e, null)}
        transform={`translate(${s.screen}) scale(${1 / scale})`}
      >
        {stackedPaths(s.stackPaths, isSwap)}
      </g>
    ));
  }, [handleCountryHover, handleLockCountry, scale, stacks, isSwap]);

  return (
    <g id="MapRenderer" key="MapRenderer" className="MapRenderer">
      <g
        key="countries"
        className="countries"
        transform={`
          translate(${width / 2}, ${height / 2})
          matrix(${rotationMatrix})
          translate(${-width / 2}, ${-height / 2})
        `}
        style={{ strokeWidth: 1 / scale }}
      >
        <path
          key="background"
          className="background"
          transform={`translate(${-2 / scale}, ${2 / scale})`}
          d={paths.outline}
        />
        {pathEls}
      </g>
      <g key="stacks" className="stacks" transform={`translate(${stacksX}, ${stacksY})`}>
        {shadowEls}
        {stackedEls}
      </g>
    </g>
  );
}

//ECB Star with blue stack

const EuroStack = ({ width, yOffset }) => {
  const iconDimensions = { height: 6, width: 8 };
  const scale = width / iconDimensions.width;
  const xOffset = (iconDimensions.width / 2 - 0.5) * scale;

  return (
    <g transform={`translate(${-xOffset - 4},${-yOffset - 6}) scale(${scale})`}>
      <path
        d="M1 4.655V7.795C1 7.915 1.07 8.035 1.21 8.125L6.2 11.315C6.48 11.495 6.94 11.495 7.22 11.315L12.21 8.125C12.35 8.035 12.42 7.915 12.42 7.795V4.655H1Z"
        fill="#0094c1"
        stroke="black"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.21 4.59315C12.49 4.78766 12.49 5.10105 12.21 5.29556L7.22 8.74282C6.94 8.93734 6.48 8.93734 6.2 8.74282L1.21 5.29556C0.93 5.10105 0.93 4.78766 1.21 4.59315L6.2 1.14589C6.48 0.951371 6.94 0.951371 7.22 1.14589L12.21 4.59315Z"
        fill="#0094c1"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M6.71 12.2873L1 8.5375V5.14427L6.71 8.89411V12.2873Z" fill="#D9D9D9" />
      </g>
      <g transform="translate(3.1,1.3) scale(1.1)">
        
      <path
        clipRule="evenodd"
        d="M0.963344 0.901405L1.7355 2.53518L0.190397 3.67526L2.75183 3.93488L4.08891 5.36673L4.91782 3.89513L7.32958 3.62044L5.34463 2.53771L5.37402 0.880108L3.35583 1.66204L0.963344 0.901405Z"
        fill="#3C3C3C"
        fillRule="evenodd"
        transform={`translate(${-0.5 * scale},${0.75 * scale})`}
      />
      <path
        clipRule="evenodd"
        d="M0.963344 0.901405L1.7355 2.53518L0.190397 3.67526L2.75183 3.93488L4.08891 5.36673L4.91782 3.89513L7.32958 3.62044L5.34463 2.53771L5.37402 0.880108L3.35583 1.66204L0.963344 0.901405Z"
        fill="#F5D311"
        fillRule="evenodd"
      />


      </g>
    </g>
  );
};
