import React, { useMemo, useState } from 'react';

import caretDown from '../../images/caret-down.svg';
import tooltipIcon from '../../images/tooltip-icon.svg';
import { formatAs } from '../../utils';
import {
  arrangementTimeseriesField,
  arrangementTimeseriesFieldFormatted,
  incomeGroupSortOrder,
  issuerAndFacilityField,
  gdpYearField,
  incomeGroupField,
  issuerFieldExtended,
  loanField,
  rfaMembershipField,
  sortByIssuer,
  unknownString,
  unlimitedField,
  validIssuers,
  ecbAccessField,
} from '../../DataLoader';
import Summary from '../Summary';

import './styles.scss';

function CountryHeader({ country, countryData, handleCountryHover }) {
  const {
    [gdpYearField]: gdp,
    [incomeGroupField]: incomeGroup,
    [rfaMembershipField]: rfaMembership,
  } = countryData;

  return (
    <tr className="border-top-bottom" key={`${country}-header`}>
      <td className="table-subheader country">
        {country}{' '}
        <img
          alt="hover for tooltip icon"
          className="tooltip-hover-icon"
          onMouseMove={e => handleCountryHover(e, country)}
          onMouseOut={e => handleCountryHover(e, null)}
          src={tooltipIcon}
        />
      </td>
      <td className="table-subheader">{incomeGroup}</td>
      <td className="table-subheader">{gdp ? formatAs.millionsString(gdp, 4) : ''}</td>
      <td className="table-subheader">{rfaMembership}</td>
      <td className="table-subheader"></td>
      <td className="table-subheader no-border"></td>
    </tr>
  );
}

function AgreementRows({ country, countryData }) {
  const headers = [
    { label: 'Issuer Type', center: false },
    { label: 'Issuer and Facility', center: false },
    { label: 'Date of Arrangement', center: true },
    { label: 'US$ Amount', center: true },
  ];

  const sortedRows = [...countryData.filteredRows].sort((a, b) => {
    if (a[issuerFieldExtended] === b[issuerFieldExtended]) {
      return +b[arrangementTimeseriesFieldFormatted] - +a[arrangementTimeseriesFieldFormatted];
    }
    return sortByIssuer(a[issuerFieldExtended], b[issuerFieldExtended]);
  });

  return (
    <React.Fragment key={`${country}-header`}>
      <tr>
        <td></td>
        {headers.map(h => (
          <td className={`agreements-header ${h.center ? 'center-text' : ''}`} key={h.label}>
            {h.label}
          </td>
        ))}
        <td></td>
      </tr>

      {sortedRows.map((d, i) => {
        const issuer = d[issuerFieldExtended];
        const issuerData = validIssuers.find(vi => vi.dataLabel === issuer);
        const issuerKey = issuerData ? issuerData.key : '';
        const className = `issuer-cell ${issuerKey}`;
        let loanAmountField = null;
        //Display of Unlimited or related value
        if (d[unlimitedField] === true) {
          if(d[ecbAccessField] === true) {
            loanAmountField = 'Unlimited via ECB';
          } else {
            loanAmountField = 'Unlimited';
          }
        } else if (d[loanField + '_raw'] === unknownString) {
          loanAmountField = '';
        } else {
          loanAmountField = formatAs.millionsString(d[loanField], 4);
        }
        return (
          <tr key={`${issuer}-${d.key}`}>
            {i === 0 ? (
              <td className="agreement-label" rowSpan={countryData.filteredRows.length}>
                Agreements
              </td>
            ) : null}
            <td className={className}>{issuer}</td>
            <td className={className}>{d[issuerAndFacilityField]}</td>
            <td className={className + ' center-text'}>{d[arrangementTimeseriesField]}</td>
            <td className={className + ' center-text'}>{loanAmountField}</td>
            <td></td>
          </tr>
        );
      })}
      <tr className="spacer"></tr>
    </React.Fragment>
  );
}

const MemoizedCountryHeader = React.memo(CountryHeader);
const MemoizedAgreementRows = React.memo(AgreementRows);

export default function CovidAgreementsTable({
  activeIssuers,
  activeYear,
  dataAll,
  handleCountryHover,
  lockedCountry,
  rowsByCountry,
}) {
  const [activeSort, setActiveSort] = useState(0);
  const [isAscending, setIsAscending] = useState(false);

  const countrySortDescending = ([countryA], [countryB]) => countryA.localeCompare(countryB);
  const countrySortAscending = ([countryA], [countryB]) => countryB.localeCompare(countryA);

  const tableHeaders = useMemo(
    () => [
      {
        key: 'country',
        label: 'Country',
        sortAscending: countrySortAscending,
        sortDescending: countrySortDescending,
      },
      {
        key: 'income-group',
        label: 'Income Group',
        sortAscending: ([countryA, countryAData], [countryB, countryBData]) => {
          if (countryAData[incomeGroupField] === countryBData[incomeGroupField]) {
            return countrySortDescending([countryA], [countryB]);
          }
          return (
            incomeGroupSortOrder[countryAData[incomeGroupField]] -
            incomeGroupSortOrder[countryBData[incomeGroupField]]
          );
        },
        sortDescending: ([countryA, countryAData], [countryB, countryBData]) => {
          if (countryAData[incomeGroupField] === countryBData[incomeGroupField]) {
            return countrySortDescending([countryA], [countryB]);
          }
          return (
            incomeGroupSortOrder[countryBData[incomeGroupField]] -
            incomeGroupSortOrder[countryAData[incomeGroupField]]
          );
        },
      },
      {
        key: 'gdp-2022',
        label: 'GDP (as of 2022)',
        sortAscending: ([countryA, countryAData], [countryB, countryBData]) => {
          if (countryAData[gdpYearField] === countryBData[gdpYearField]) {
            return countrySortDescending([countryA], [countryB]);
          }
          return countryAData[gdpYearField] - countryBData[gdpYearField];
        },
        sortDescending: ([countryA, countryAData], [countryB, countryBData]) => {
          if (countryAData[gdpYearField] === countryBData[gdpYearField]) {
            return countrySortDescending([countryA], [countryB]);
          }
          return countryBData[gdpYearField] - countryAData[gdpYearField];
        },
      },
      {
        key: 'rfa-member',
        label: 'RFA',
        sortAscending: ([countryA, countryAData], [countryB, countryBData]) => {
          if (countryAData[rfaMembershipField] === countryBData[rfaMembershipField]) {
            return countrySortDescending([countryA], [countryB]);
          }
          return countryBData[rfaMembershipField].localeCompare(countryAData[rfaMembershipField]);
        },
        sortDescending: ([countryA, countryAData], [countryB, countryBData]) => {
          if (countryAData[rfaMembershipField] === countryBData[rfaMembershipField]) {
            return countrySortDescending([countryA], [countryB]);
          }
          return countryAData[rfaMembershipField].localeCompare(countryBData[rfaMembershipField]);
        },
      },
    ],
    []
  );

  const handleSetActiveSort = i => {
    if (activeSort === i) {
      setIsAscending(!isAscending);
      return;
    }
    setActiveSort(i);
    setIsAscending(false);
  };

  const sortKey = isAscending ? 'sortAscending' : 'sortDescending';

  const countries = lockedCountry
    ? [[lockedCountry, rowsByCountry[lockedCountry]]]
    : Object.entries(rowsByCountry).sort(tableHeaders[activeSort][sortKey]);

  return (
    <div className="DataTable AgreementsTable">
      <div className="toHide">
      <h2>TOTAL AGREEMENT AMOUNTS BY ISSUER TYPE (2020-2022)</h2>
      <Summary activeIssuers={activeIssuers} activeYear={activeYear} dataAll={dataAll} />
      </div>
      <div className="table-wrapper">
        <table>
          <tbody>
            <tr>
              {tableHeaders.map((h, i) => (
                <th key={h.label} onClick={() => handleSetActiveSort(i)}>
                  <div className="header-wrapper">
                    {h.label}
                    {activeSort === i && (
                      <img
                        alt="Sort"
                        src={caretDown}
                        style={{ transform: `rotate(${isAscending ? 180 : 0}deg)` }}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
            {countries.map(([country, countryData]) => {
              if (!countryData.filteredRows.length) {
                return null;
              }
              return (
                <React.Fragment key={country}>
                  <MemoizedCountryHeader
                    country={country}
                    countryData={countryData}
                    handleCountryHover={handleCountryHover}
                  />
                  <MemoizedAgreementRows country={country} countryData={countryData} />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
