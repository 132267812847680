import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';

import Covid from './Components/Covid';
import Header from './Components/Header';
import Lending from './Components/Lending';
import Navigation from './Components/Navigation';
import DataLoader, { initialCovidDataState, initialLendingDataState } from './DataLoader';

import './App.scss';
import Modal from './Components/Modal';
import Introduction from './Components/Introduction';

let aspectRatio = 0.4;

function App() {
  const [showIntro, setShowIntro] = useState(true);
  const [view, setView] = useState('lending');
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
  const [dataCovid, setDataCovid] = useState(initialCovidDataState);
  const [dataLending, setDataLending] = useState(initialLendingDataState);
  const [mapSize, setMapSize] = useState({
    width: document.body.clientWidth,
    height: document.body.clientWidth * aspectRatio,
  });

  const headersRef = useRef(null);

  useEffect(() => {
    DataLoader.loadCovidData().then(res => {
      setDataCovid(res);
    });
    DataLoader.loadLendingData().then(res => {
      setDataLending(res);
    });
    DataLoader.loadLastUpdatedDate().then(res => {
      setLastUpdatedDate(res);
    });
  }, []);

  useEffect(() => {
    function _measure() {
      const width = document.body.clientWidth;
      if (width <= 480) {
        aspectRatio = 0.75;
      } else {
        aspectRatio = 0.4;
      }
      const height = width * aspectRatio;
      setMapSize({ width, height });
    }
    _measure();
    const debouncedMeasure = debounce(_measure, 100, {
      leading: true,
      trailing: true,
    });
    window.addEventListener('resize', debouncedMeasure);
    return function cleanup() {
      window.removeEventListener('resize', debouncedMeasure);
    };
  }, []);

  return (
    <div className="App">
      <Modal type="intro" show={showIntro} setShow={setShowIntro}>
        <Introduction setShow={setShowIntro} />
      </Modal>
      <div ref={headersRef}>
        <Header />
        <Navigation headersRef={headersRef} view={view} setView={setView} />
      </div>
      {view === 'covid' ? (
        <Covid dataAll={dataCovid} lastUpdated={lastUpdatedDate} mapSize={mapSize} />
      ) : (
        <Lending dataAll={dataLending} lastUpdated={lastUpdatedDate} mapSize={mapSize} />
      )}
    </div>
  );
}

export default App;
