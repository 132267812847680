import React from 'react';

import un from '../../images/unctad_logo_white.png';
import logo from '../../images/bu-logo.png';
import freie from '../../images/Freie_Universitaet_Berlin_logo white.png';

import './styles.scss';

const PROJECT_LINK = 'https://www.bu.edu/gdp/';
const UN_LINK = 'https://unctad.org/';
const FUB_LINK = 'https://www.fu-berlin.de/';


export default function Header(props) {
  //const { headersRef, setView, view } = props;
  return (
    <div className="Header">
      <div className="logo-small">
        <a href={PROJECT_LINK} target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Boston University" />
        </a>
      </div>
      <div className="partners">
        <div className="logo">
          <a href={FUB_LINK} target="_blank" rel="noopener noreferrer">
            <img src={freie} alt="Freie Universitaet Berlin" />
          </a>
        </div>
        <div className="logo">
          <a href={UN_LINK} target="_blank" rel="noopener noreferrer">
            <img src={un} alt="United Nation UNCTAD" />
          </a>
        </div>
      </div>
      
    </div>
  );
}
