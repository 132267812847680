import { bin, extent, max } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { timeMonth, timeYear } from 'd3-time';
import debounce from 'lodash.debounce';
import React, { useLayoutEffect, useRef, useState } from 'react';

import { ALL_YEARS, colors } from '../../consts';
import {
  arrangementTimeseriesFieldFormatted,
  issuerFieldExtended,
  sortByIssuer,
} from '../../DataLoader';
import { formatAs, lastDateOfQuarter, parseTime } from '../../utils';

import './styles.scss';

function AgreementsTooltip({ agreementsHover }) {
  const { count, visible, x, y } = agreementsHover;
  if (!visible) {
    return null;
  }
  return (
    <div className="AgreementsTooltip" style={{ left: x, top: y }}>
      {count}
    </div>
  );
}

function AgreementsTimeseriesChart({ activeYear, rowsByCountry, setAgreementsHover, width }) {
  const minimumDate =
    activeYear === ALL_YEARS ? parseTime('01/01/2020') : parseTime(`01/01/${activeYear}`);

  const data = Object.entries(rowsByCountry)
    .map(([_, countryData]) => countryData.filteredRows)
    .flat(1)
    .filter(row => +row[arrangementTimeseriesFieldFormatted] > +minimumDate);

  const agreementTimeExtentRaw = extent(data, d => d[arrangementTimeseriesFieldFormatted]);

  let startDate = timeYear.floor(agreementTimeExtentRaw[0]);
  let endDate = lastDateOfQuarter(agreementTimeExtentRaw[1]);

  let dateThreshold = timeMonth.every(3);
  let agreementTimeExtent = [
    startDate,
    endDate,
  ];

  const thresholds = dateThreshold.range(...agreementTimeExtent);

  const padding = {
    b: 25,
    l: 20,
    r: 20,
    t: 10,
  };

  const innerWidth = width - padding.l - padding.r;

  const height = 140;

  const innerHeight = height - padding.t - padding.b;

  const dateBins = bin()
    .domain(agreementTimeExtent)
    .thresholds(thresholds)
    .value(d => d[arrangementTimeseriesFieldFormatted])(data);

  const binCounts = dateBins.map(bin => ({
    date: bin.x0,
    count: bin.length,
    y: 0,
  }));
  const binMax = max(binCounts, d => d.count);

  const xScale = scaleTime().domain(agreementTimeExtent).range([0, innerWidth]);
  const yScale = scaleLinear().domain([0, binMax]).range([0, innerHeight]);

  const majorTicks = thresholds
    .concat([agreementTimeExtent?.[1]])?.filter(v => v?.getMonth() === 0)
    .map(v => {
      const tickString = formatAs.year(v);
      return {
        x: xScale(v),
        tickString,
        value: v,
      };
    });

  const minorTicks = thresholds.map(v => {
    return {
      x: xScale(v),
      value: v,
    };
  });

  // Ensures we have a final minor tick on the x axis
  minorTicks.push({
    x: xScale(agreementTimeExtent[1]),
    value: agreementTimeExtent[1],
  });

  const majorTickHight = 5;
  const minorTickHeight = 2;

  const barPadding = 2;
  const barWidth =
    thresholds[0] && thresholds[1]
      ? xScale(thresholds[1]) - xScale(thresholds[0])
      : xScale(agreementTimeExtent[1]) - xScale(agreementTimeExtent[0]);
  const minHeightForAnnotation = 15;

  const rects = dateBins
    .map((bins, binIndex) => {
      const byIssuer = bins.reduce((a, b) => {
        if (!a[b[issuerFieldExtended]]) {
          a[b[issuerFieldExtended]] = 0;
        }
        a[b[issuerFieldExtended]] += 1;
        return a;
      }, {});
      const dateRects = Object.entries(byIssuer)
        .filter(([_, issuerValue]) => issuerValue !== 0)
        .map(([issuer, issuerValue]) => ({
          fill: colors[issuer],
          height: yScale(issuerValue),
          issuer,
          key: `${+bins.x1}-${issuer}`,
          value: issuerValue,
          width: xScale(bins.x1) - xScale(bins.x0) - barPadding * 2,
          x: xScale(bins.x0) + barPadding,
        }))
        .reverse()
        .sort((a, b) => sortByIssuer(a.issuer, b.issuer));
      dateRects.forEach((d, i) => {
        const stackValue = dateRects[i - 1] ? innerHeight - dateRects[i - 1].y : 0;
        d.y = innerHeight - d.height - stackValue;
        d.transformY = i * -2; // space between the stacks
        binCounts[binIndex].y = d.y + d.transformY;
      });
      return dateRects;
    })
    .reduce((a, b) => a.concat(b), []);

  const handleAgreementsHover = data => {
    if (data === null) {
      setAgreementsHover({ count: null, visible: false, x: 0, y: 0 });
      return;
    }
    setAgreementsHover({
      count: data.value,
      visible: true,
      x: data.x + barWidth + padding.l,
      y: data.y,
    });
  };

  return (
    <svg height={height} width={width}>
      <g transform={`translate(${padding.l},${padding.t})`}>
        <g>
          {rects.map(d => (
            <rect
              height={d.height}
              key={d.key}
              fill={d.fill}
              onMouseMove={e => handleAgreementsHover(d)}
              onMouseOut={e => handleAgreementsHover(null)}
              transform={`translate(0,${d.transformY})`}
              width={d.width}
              x={d.x}
              y={d.y}
            />
          ))}
        </g>
        <g transform={`translate(0,${innerHeight})`}>
          {minorTicks.map(d => {
            return (
              <g key={+d.value}>
                <line className="tick" x1={d.x} x2={d.x} y1={2} y2={2 + minorTickHeight} />
              </g>
            );
          })}
          {majorTicks.map(d => {
            return (
              <g key={d.tickString}>
                <line className="tick" x1={d.x} x2={d.x} y1={2} y2={2 + majorTickHight} />
                <text className="tick-text" x={d.x} y={majorTickHight + 2}>
                  {d.tickString}
                </text>
              </g>
            );
          })}
        </g>
        <g>
          {rects
            .filter(rect => rect.height > minHeightForAnnotation)
            .map(({ key, transformY, value, x, y }) => (
              <text
                className="totals-text"
                key={`${key}-${value}`}
                x={x + barWidth / 2 - barPadding}
                y={y + transformY}
              >
                {value}
              </text>
            ))}
        </g>
      </g>
    </svg>
  );
}

const MemoizedAgreementsTimeseriesChart = React.memo(AgreementsTimeseriesChart);

export default function AgreementsTimeseries({ activeYear, rowsByCountry }) {
  const chartRef = useRef(null);
  const [width, setChartWidth] = useState(0);
  const [agreementsHover, setAgreementsHover] = useState({
    count: null,
    visible: false,
    x: 0,
    y: 0,
  });

  useLayoutEffect(() => {
    function _measure() {
      setChartWidth(chartRef.current.offsetWidth);
    }
    _measure();
    const debouncedMeasure = debounce(_measure, 100, { leading: true, trailing: true });
    window.addEventListener('resize', debouncedMeasure);
    return function cleanup() {
      window.removeEventListener('resize', debouncedMeasure);
    };
  }, []);

  return (
    <div className="AgreementsTimeseries">
      <div className="instruction">
      Roll over color bars to show the number of agreements over time.
      </div>
      <div className="chart" ref={chartRef}>
        <MemoizedAgreementsTimeseriesChart
          activeYear={activeYear}
          rowsByCountry={rowsByCountry}
          setAgreementsHover={setAgreementsHover}
          width={width}
        />
        <AgreementsTooltip agreementsHover={agreementsHover} />
      </div>
    </div>
  );
}
