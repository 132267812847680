import { useState } from 'react';
import { useEffect } from 'react';
import facebook from '../../images/facebook.png';
import twitter from '../../images/twitter.png';
import Modal from '../Modal';

import './styles.scss';

const link = window.location.href;

const tweetText = `Explore the Global Financial Safety Net Tracker, a tool built by @GDP_Center @FU_Berlin @UNCTAD: `;
const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
  tweetText
)}&url=${encodeURIComponent(link)}`;

const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;

function AboutCopy() {
  return (
    <>
      <div className="AboutContainer">
        <h1>About / Data Download</h1>
        <p>
          The Global Financial Safety Net (GFSN) Tracker is the first global interactive database
          that measures the annual lending capacity of the International Monetary Fund (IMF),
          central banks and regional financial arrangements (RFAs) and the total amount of financing
          to combat the COVID-19 crisis via loans from the IMF, RFAs and currency swaps. The
          interactive is co-produced by Freie Universität Berlin, the Boston University Global
          Development Policy Center and the United Nations Conference on Trade and Development
          (UNCTAD).
        </p>
        <p>The GFSN Tracker comprises two components, displayed in two tabs:</p>
        <ul>
          <li>
            <strong>The Annual Lending Capacity</strong> tab shows a country's access limit to
            crisis finance in the GFSN that can be obtained from the three primary sources: the IMF,
            RFAs and central bank currency swaps. The data displayed estimates the maximum crisis
            financing available to a country from either the IMF or RFAs, if the country is a member
            of an RFA. Further, the lending capacity shows the total of central bank currency swaps
            that a country's central bank has with a counterparty central bank in a given year, as
            an approximation of the potentially bilaterally available amount of liquidity available
            to draw.
          </li>
          <li>
            <strong>The COVID-19 Financing Arrangements</strong> tab tracks COVID-19 financing
            arrangements, displaying the total amount of financing via loans from the IMF, RFAs and
            from currency swaps that were deployed as a response to the COVID-19 pandemic. This is
            the first COVID-19 tracking source that includes a regularly updated overview of all RFA
            loans and central bank currency swaps disbursed in reaction to the COVID-19 pandemic.
          </li>
        </ul>
        <h2>Definitions:</h2>
        <p>
          <b>International Monetary Fund (IMF):</b> Data on access limits for loan agreements with
          the IMF show cumulative conditional and unconditional access for the assumed time period
          of three years. Cumulative access is calculated based on the information given in the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.imf.org/~/media/Files/Publications/PP/2017/pp110917-2017-handbook-of-imf-facilities-for-low-income-countries.ashx"
          >
            2017 Handbook on IMF Facilities for Low-Income Countries
          </a>{' '}
          and in the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.imf.org/en/Publications/Policy-Papers/Issues/2021/12/23/Review-Of-Temporary-Modifications-To-The-Funds-Access-Limits-In-Response-To-The-Covid-19-511281"
          >
            temporary modifications
          </a>{' '}
          of IMF access limits during the acute COVID-19 crisis, the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.imf.org/en/Publications/Policy-Papers/Issues/2023/04/21/2023-Handbook-of-IMF-Facilities-for-Low-Income-Countries-532709"
          >
            2023 Handbook of IMF Facilities for Low-Income Countries
          </a>{' '}
          as well as an October 2023{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.imf.org/en/Publications/Policy-Papers/Issues/2023/10/05/Review-of-the-Flexible-Credit-Line-The-Short-Term-Liquidity-Line-and-the-Precautionary-and-540123"
          >
            IMF Policy Paper
          </a>{' '}
          titled 'Review of the Flexible Credit Line, The Short Term Liquidity Line and the
          Precautionary and Liquidity Line, and Proposals for Reform.' IMF loans shown as
          unconditional include the Rapid Finance Instrument (RFI), the Rapid Credit Facility (RCF),
          the Flexible Credit Line (FCL), the Precautionary and Liquidity Line (PLL) and the
          Short-term Liquidity Line (SLL). IMF loans shown as conditional loans include the Stand-By
          Arrangements (SBA), the Standard Credit Facility (SCF), the Extended Fund Facility (EFF),
          the Catastrophe Containment and Relief Trust (CCRT), the Extended Credit Facility (ECF)
          and the Resilience and Sustainability Facility (RSF).
        </p>
        <p>
          <b>Regional Financial Arrangements (RFAs):</b> RFAs are regional pools of foreign exchange
          reserves by their member countries. The GFSN Tracker includes the following RFAs: Arab
          Monetary Fund (AMF), Latin American Reserve Fund (FLAR), Eurasian Fund for Economic
          Stabilization and Development (EFSD), European Stabilization Mechanism (ESM), European
          Union Macro-Financial Assistance (EU MFA), European Balance of Payments Assistance
          Facility (EU BOP), Chiang Mai Initiative Multilateralization (CMIM), Contingent Reserve
          Arrangement (CRA) and the South Asian Association for Regional Cooperation Swap
          Arrangement (SAARC). Data for access limits comes directly from the RFAs’ websites and
          annual reports. RFA access limits are calculated based on the maximum amount a country can
          borrow or swap through the RFA for an assumed time period of three years. Balance of
          payments financing by RFAs is realized either through loan agreements or through central
          bank currency swaps that can be activated. Data on realized RFA loan or swap arrangements
          are taken from RFA websites and annual reports.
        </p>
        <p>
          <b>Bilateral Swap/Arrangements (BSAs):</b> A bilateral central bank currency swap is an
          agreement between two central banks to exchange their respective currencies to obtain
          foreign currency liquidity. The GFSN Tracker counts bilateral currency swaps between
          emerging and developing economies and bilateral currency swaps between advanced economies
          twice. The assumption is that those currency swaps between countries of the same income
          group classification are of mutual use. The GFSN Tracker counts bilateral currency swaps
          between emerging or developing economies and advanced economies once. The assumption is
          that those currency swaps between countries of different income group classifications are
          of unidirectional purpose with the beneficiary being an emerging or developing economy.
          Furthermore, bilateral currency swaps of emerging or developing economies with the
          People's Bank of China (PBOC) are counted once in accordance with the latter assumption of
          unidirectional purpose and the beneficiary being an emerging or developing economy.
          Current swap data are collected from central bank websites and media reports. Data on PBOC
          currency swaps are taken from the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.pbc.gov.cn/en/3688241/3688636/3828468/3982952/index.html"
          >
            RMB internationalization reports
          </a>{' '}
          .
        </p>
        <p>
          <b>Suggested citation:</b> Mühlich, Laurissa, Barbara Fritz, William N. Kring, Marina
          Zucker-Marques, Natalia Gaitán. 2024. "Global Financial Safety Net Tracker." Retrieved
          from{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bu.edu/gdp/global-financial-safety-net-tracker/index.html"
          >
            https://www.bu.edu/gdp/global-financial-safety-net-tracker/
          </a>{' '}
        </p>
        <h2>Resources</h2>
        <p>
          For information on data methodology, please refer to the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bu.edu/gdp/2024/07/02/global-financial-safety-net-tracker-database-methodology-guidebook/"
          >
            Global Financial Safety Net Tracker Database Methodology Guidebook
          </a>
          .
        </p>
        <p>For research and commentary, please refer to:</p>

        <ul>
          <li>
            Mühlich, Laurissa, Barbara Fritz and William N. Kring. 2024. “Does Global Crisis Finance
            Sustain a Middle-income Trap? Insights from the Updated Global Financial Safety Net
            Tracker.” Boston University Global Development Policy Center.
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.bu.edu/gdp/2024/07/10/does-global-crisis-finance-sustain-a-middle-income-trap-insights-from-the-updated-global-financial-safety-net-tracker/"
            >
              https://www.bu.edu/gdp/2024/07/10/does-global-crisis-finance-sustain-a-middle-income-trap-insights-from-the-updated-global-financial-safety-net-tracker/
            </a>
          </li>

          <li>
            Mühlich, Laurissa and Marina Zucker-Marques. 2023. “Closing the Global Crisis Finance
            Gap: Why and How the IMF Should Address Weaknesses in the Global Financial Safety Net.”
            Boston University Global Development Policy Center. GEGI Policy Brief 25.
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.bu.edu/gdp/2023/07/31/closing-the-global-crisis-finance-gap-why-and-how-the-imf-should-address-weaknesses-in-the-global-financial-safety-net/"
            >
              https://www.bu.edu/gdp/2023/07/31/closing-the-global-crisis-finance-gap-why-and-how-the-imf-should-address-weaknesses-in-the-global-financial-safety-net/
            </a>
          </li>
          <li>
            Mühlich, Laurissa, Marina Zucker-Marques, Barbara Fritz and William N. Kring. 2022. “No
            One left Behind? COVID-19 and the Shortcomings of the Global Financial Safety Net for
            Low- and Middle-income Countries.” UNCTAD Research Paper.
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://mobilizingdevfinance.org/research-material/no-one-left-behind-covid-19-and-shortcomings-global-financial-safety-net-low-and"
            >
              https://mobilizingdevfinance.org/research-material/no-one-left-behind-covid-19-and-shortcomings-global-financial-safety-net-low-and
            </a>
          </li>
          <li>
            Zucker-Marques, Marina, Laurissa Mühlich and Barbara Fritz. 2022. "Unequal Access to The
            Global Financial Safety Net: An Index for the Quality of Crisis Finance."
            <br />
            Unpublished manuscript.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.boeckler.de/pdf/v_2022_10_21_fritz.pdf"
            >
              https://www.boeckler.de/pdf/v_2022_10_21_fritz.pdf
            </a>
          </li>
          <li>
            Mühlich, Laurissa, Barbara Fritz and William N. Kring. “No One Left Behind? Assessing
            the Global Financial Safety Net Performance During COVID-19” Journal of Globalization
            and Development 13, no. 1 (2022): 123-147.
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.degruyter.com/document/doi/10.1515/jgd-2021-0033/html"
            >
              https://www.degruyter.com/document/doi/10.1515/jgd-2021-0033/html
            </a>
          </li>

          <li>
            Mühlich, Laurissa, Barbara Fritz and William N. Kring. 2021. “Towards the
            Marginalization of Multilateral Crisis Finance? The Global Financial Safety Net and
            COVID-19.” Boston University Global Development Policy Center. GEGI Policy Brief 15.
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.bu.edu/gdp/2021/04/27/towards-the-marginalization-of-multilateral-crisis-finance-the-global-financial-safety-net-and-covid-19/"
            >
              https://www.bu.edu/gdp/2021/04/27/towards-the-marginalization-of-multilateral-crisis-finance-the-global-financial-safety-net-and-covid-19/
            </a>
            .
          </li>

          <li>
            Mühlich, Laurissa, Barbara Fritz, William N. Kring and Kevin P. Gallagher. 2020. “The
            Global Financial Safety Net Tracker: Lessons for the COVID-19 Crisis from a New
            Interactive Dataset.” Boston University Global Development Policy Center. GEGI Policy
            Brief 10.
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.bu.edu/gdp/2020/11/30/the-global-financial-safety-net-tracker-lessons-for-the-covid-19-crisis-from-a-new-interactive-dataset-2/"
            >
              https://www.bu.edu/gdp/2020/11/30/the-global-financial-safety-net-tracker-lessons-for-the-covid-19-crisis-from-a-new-interactive-dataset-2/
            </a>
          </li>
        </ul>
        <p>
          <b>Tracker Last Updated:</b> July 10, 2024
        </p>

        <h2>Contact</h2>
        <p>
          For technical inquiries or suggestions regarding this database, please contact{' '}
          <a href="mailto:gegidata@bu.edu">gegidata@bu.edu</a>.
        </p>
        <p>
          For media inquiries, please contact Maureen Heydt,{' '}
          <a href="mailto:mheydt@bu.edu">mheydt@bu.edu</a>.
        </p>

        <h2>Acknowledgments</h2>
        <p>
          The Boston University Global Development Policy Center thanks Joshua D. Pitts for his help
          in designing the initial interactive and Sebastian Juan Gomez for his research assistance.
          The GDP Center would also like to thank the United Nations Conference on Trade and
          Development, Rockefeller Brothers Fund and Carnegie Corporation of New York for the
          financial support that enabled this research and interactive database. We also thank
          Maureen Heydt and Samantha Igo for their invaluable communications and outreach support.
        </p>
        <p>
          The Global Financial Safety Net Tracker was visualized by{' '}
          <a target="_blank" rel="noreferrer" href="https://www.pitchinteractive.com">
            Pitch Interactive
          </a>
          .
        </p>
        <h2>Data Download</h2>
        <p>To download the data, please fill out the form submission below:</p>
      </div>
    </>
  );
}

export default function About({ headersRef, selectedPage }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '8473896',
        formId: '796dabdd-43ce-47c6-af52-66c2a9955836',
        target: '#hubspot-form',
      });
    } else {
      console.warn('Error loading hubspot form');
    }
  }, []);

  return (
    <div className="About">
      <div className="links">
        <div className="about-link" onClick={() => setShow(!show)}>
          About / Data Download
        </div>
        <a href={twitterURL} target="_blank" rel="noreferrer">
          <img src={twitter} alt="twitter" />
        </a>
        <a href={facebookUrl} target="_blank" rel="noreferrer">
          <img src={facebook} alt="facebook" />
        </a>
      </div>
      <Modal
        type="about"
        show={show}
        setShow={setShow}
        style={{
          top: headersRef.current ? headersRef.current.getBoundingClientRect().height + 'px' : 0,
        }}
      >
        <AboutCopy />
        <div id="hubspot-form" className="hubspot" />
      </Modal>
    </div>
  );
}