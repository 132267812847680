import axios from 'axios';
import { csvParse } from 'd3-dsv';
import { timeParse } from 'd3-time-format';

import countryLocations from './Components/Map/countryMetadata';
import { ALL_YEARS } from './consts';
import { logDebug, numericParse, parseTime, uniq, warnDebug } from './utils';

const lastUpdatedFile = 'Last_Updated';
const covidDataFile = 'UN_GFSN_COVID19';
const lendingDataFile = 'Capacity_GFSN_2018_2023';

const arrangementTimeseriesField = 'Date of Arrangement';
const arrangementTimeseriesFieldFormatted = 'arrangementDate'; // Field formatted to JS Date
const expirationTimeseriesField = 'Expiration Date';
const expirationTimeseriesFieldFormatted = 'Expiration Date';
const arrangementYearFieldFormatted = 'arrangementYear';
const countryField = 'Country';
const ecbAccessField = 'ECB';
const gdpYearField = 'GDP 2022';
const gdpField = 'GDP';
const imf3YearConditionalField = 'IMF Conditional';
const imf3YearUnconditionalField = 'IMF Unconditional';
const incomeGroupFieldCapacity = 'Income Group';
const incomeGroupField = 'Income Group 2022';
const issuerAndFacilityField = 'Facility/Partner';
const issuerConditionalityField = 'IMF conditionality';
const issuerField = 'Issuer Type';
const issuerFieldExtended = 'Issuer Type Extended';
const loanField = 'USD Amount Approved';
const permanentString = 'permanent';
const rfa3YearField = 'RFA';
const rfaMembershipFieldCapacity = 'RFA Member'; // Different headers for the capacity dataset
const rfaMembershipField = 'RFA Membership';
const swapFieldCapacity = 'Swap';
const swapFieldNewRenewExtend = 'Swap New/Renew/Extended';
const unknownString = 'unknown'; // This field value indicates the loan value is unknown
const unlimitedFieldCapacity = 'Unlimited';
const unlimitedField = 'Unlimited';
const yearField = 'Year';

const validIssuers = [
  {
    key: 'imf-conditional',
    dataLabel: 'IMF Conditional',
  },
  {
    key: 'imf-unconditional',
    dataLabel: 'IMF Unconditional',
  },
  {
    key: 'rfa',
    dataLabel: 'RFA',
  },
  {
    key: 'swap',
    dataLabel: 'Swap',
  },
];

const issuerSortOrder = {
  'IMF Conditional': 1,
  'IMF Unconditional': 2,
  RFA: 3,
  Swap: 4,
};

function getIssuerDataKey(filterValue) {
  const issuer = validIssuers.find(vi => vi.key === filterValue);
  if (!issuer) {
    return undefined;
  }
  return issuer.dataLabel;
}

const incomeGroupSortOrder = {
  'Low income': 1,
  'Lower middle income': 2,
  'Upper middle income': 3,
  'High income': 4,
};

// TODO: Should be separate rows
function formatIssuer(row) {
  const [issuer] = row[issuerField].split(' ');
  return issuer + (row[issuerConditionalityField] ? ` ${row[issuerConditionalityField]}` : '');
}

function formatFacility(row) {
  const splitArray = row[issuerField].split(' ');
  splitArray.shift();
  return splitArray.join(' ');
}

function sortByIssuer(a, b) {
  return issuerSortOrder[a] - issuerSortOrder[b];
}

function issuerIsValid(formattedIssuerField) {
  return Boolean(validIssuers.find(vi => vi.dataLabel === formattedIssuerField));
}

/**
 * Formats issuer data to include metadata (value aggregates for the issuer)
 */
function formatIssuerMetadata(rows) {
  if (!rows.length) {
    return {};
  }
  const issuers = uniq(issuerFieldExtended, rows);
  const dataByIssuers = Object.fromEntries(
    issuers.map(issuer => [
      issuer,
      {
        countTotal: 0,
        totalByIssuer: 0,
        rows: [],
      },
    ])
  );
  rows.forEach(row => {
    const issuerEntry = dataByIssuers[row[issuerFieldExtended]];
    issuerEntry.countTotal++;
    issuerEntry.totalByIssuer += row[loanField];
    issuerEntry.rows.push({ ...row });
  });
  return dataByIssuers;
}

function getIssuerAggregates(rows) {
  const aggregates = validIssuers.reduce((a, issuer) => {
    a[issuer.dataLabel] = {
      issuer: issuer.dataLabel,
      value: 0,
    };
    return a;
  }, {});
  rows.forEach(row => {
    aggregates[row[issuerFieldExtended]].value += row[loanField];
  });
  return Object.entries(aggregates);
}

function filterRowsByIssuer(issuers) {
  return row => issuers.includes(row[issuerFieldExtended]);
}

function filterRowsByYear(year) {
  if (year === ALL_YEARS) {
    return _ => true;
  }
  const parsedYearStart = +parseTime(`1/1/${year}`);
  const parsedYearEnd = +parseTime(`1/1/${year + 1}`);
  return row => {
    const arrangementStart = +row[arrangementTimeseriesFieldFormatted];
    const arrangementEnd = row[expirationTimeseriesFieldFormatted];
    if (arrangementStart >= parsedYearStart && arrangementStart < parsedYearEnd) {
      // arrangement was started this year, include it in the list
      return true;
    }
    if (arrangementEnd === permanentString && arrangementStart < parsedYearEnd) {
      // arrangement was permanent and it started sometime before the end of the selected year, include it
      return true;
    }
    // include arrangement if the arrangement overlapped the time series
    return arrangementStart <= parsedYearEnd && +arrangementEnd >= parsedYearStart;
  };
}

function parseLoanField(value, row, accessor) {
  return numericParse(value, row, accessor);
}

const initialCovidDataState = {
  arrangementYears: [], // Will hold the agreement years for which we have data
  rowsByCountry: {}, // Will hold the data nested by country
  rows: [], // Will hold the parsed and formatted rows
};
const initialLendingDataState = { dataByYear: {}, years: [] };

class DataLoader {
  loadCovidData() {
    return new Promise(resolve => {
      const url = `${process.env.PUBLIC_URL}/data/${covidDataFile}.csv`;
      axios.get(url).then(response => {
        // Inital filtering of csv removes rows without countries and issuers
        // Also removes rows with unrecognized issuers
        const rows = csvParse(response.data)
          .filter(row => {
            let isValid = true;
            if (!row[countryField]) {
              warnDebug('Row without country found. Filtering.', row);
            } else if (!row[issuerField]) {
              warnDebug('Row without issuer found. Filtering.', row);
            } else if (!issuerIsValid(formatIssuer(row))) {
              isValid = false;
              if(row[ecbAccessField]){
                isValid = true
              }
              warnDebug(`Invalid issuer found : ${formatIssuer(row)}. Filtering.`, row);
            } else if (!parseTime(row[arrangementTimeseriesField])) {
              warnDebug('Invalid timestamp found. Filtering.', row);
            }
            return row[countryField] && row[issuerField] && isValid;
          })
          .map((row, i) => {
            // Parses some time and numeric fields and create some new ones that we'll need
            const arrangementDate = parseTime(row[arrangementTimeseriesField]);
            const expirationDate =
              row[expirationTimeseriesField] === permanentString
                ? permanentString
                : parseTime(row[expirationTimeseriesField]);
            return {
              [countryField]: row[countryField],
              [arrangementTimeseriesField]: row[arrangementTimeseriesField],
              [arrangementTimeseriesFieldFormatted]: arrangementDate,
              [arrangementYearFieldFormatted]: arrangementDate
                ? +arrangementDate.getFullYear()
                : null,
              [expirationTimeseriesField]: row[expirationTimeseriesField],
              [expirationTimeseriesFieldFormatted]: expirationDate,
              [gdpYearField]: numericParse(row[gdpYearField], row, gdpYearField),
              key: i, // Useful to have a key for each row for react. This index should never be mutated.
              [incomeGroupField]: row[incomeGroupField],
              [issuerFieldExtended]: formatIssuer(row),
              [issuerAndFacilityField]: formatFacility(row),
              [loanField + '_raw']: row[loanField],
              [loanField]: parseLoanField(row[loanField], row, loanField),
              [rfaMembershipField]: row[rfaMembershipField],
              [swapFieldNewRenewExtend]: row[swapFieldNewRenewExtend],
              [unlimitedField]: row[unlimitedField] === '1',
              [ecbAccessField]: row[ecbAccessField] === '1',
            };
          });

        // Get all the years from the dataset, but filtered to those we want to see in the dropdown
        const arrangementYears = uniq(arrangementYearFieldFormatted, rows)
          .map(year => +year)
          .sort((a, b) => b - a)
          .filter(year => year >= 2020);

        // Extract and log some uniq values from the dataset in development mode
        if (process.env.NODE_ENV === 'development') {
          const issuers = uniq(issuerFieldExtended, rows);
          logDebug(issuers.length + ' issuers found: ', issuers);

          const countries = uniq(countryField, rows);
          logDebug(countries.length + ' countries found: ', countries);

          const incomeGroup = uniq(incomeGroupField, rows).map(d => d);
          logDebug(incomeGroup.length + ' income groups found: ', incomeGroup);
        }

        // Nest data by country name
        const rowsByCountry = rows.reduce((a, row) => {
          const accessor = row[countryField];

          const location = countryLocations[accessor.trim()];
          if (!location) {
            warnDebug('No location data found for ', accessor);
          }

          const gdp = row[gdpYearField];
          const incomeGroup = row[incomeGroupField];
          const rfaMembership = row[rfaMembershipField];

          if (!a[accessor]) {
            // Create the empty country level object that will hold all the country's data
            a[accessor] = {
              [gdpYearField]: gdp,
              [incomeGroupField]: incomeGroup,
              location,
              rows: [],
              [rfaMembershipField]: rfaMembership,
            };
          }

          // These values should be the same across rows, but we validate to be sure
          const validateValues = [
            [gdp, a[accessor][gdpYearField], 'gdpYearField'],
            [incomeGroup, a[accessor][incomeGroupField], 'incomeGroupField'],
            [rfaMembership, a[accessor][rfaMembershipField], 'rfaMembershipField'],
          ];

          validateValues.forEach(v => {
            if (v[0] !== v[1]) {
              warnDebug(
                `Different ${v[2]} logged for ${row[countryField]}. This is unexpected and may reflect errors in the source data.`
              );
            }
          });

          // Populate and/or update the object with the row data
          a[accessor].rows.push(row);
          return a;
        }, {});

        resolve({
          arrangementYears,
          rowsByCountry,
          rows,
        });
      });
    });
  }

  loadLendingData() {
    return new Promise(resolve => {
      const url = `${process.env.PUBLIC_URL}/data/${lendingDataFile}.csv`;
      axios
        .get(url)
        .then(response => {
          const rows = csvParse(response.data).map(row => {
            const location = countryLocations[row[countryField].trim()];
            if (!location) {
              warnDebug('No location data found for ', row[countryField]);
            }
            return {
              ...row,
              [ecbAccessField]: row[ecbAccessField] === '1',
              [gdpField + '_raw']: row[gdpField],
              [gdpField]: numericParse(row[gdpField], row, gdpField),
              [imf3YearConditionalField + '_raw']: row[imf3YearConditionalField],
              [imf3YearConditionalField]:
                numericParse(row[imf3YearConditionalField], row, imf3YearConditionalField) || null,
              [imf3YearUnconditionalField + '_raw']: row[imf3YearUnconditionalField],
              [imf3YearUnconditionalField]:
                numericParse(row[imf3YearUnconditionalField], row, imf3YearUnconditionalField) ||
                null,
              location,
              [rfa3YearField + '_raw']: row[rfa3YearField],
              [rfa3YearField]: numericParse(row[rfa3YearField], row, rfa3YearField) || null,
              [rfaMembershipFieldCapacity]: row[rfaMembershipFieldCapacity],
              [swapFieldCapacity + '_raw']: row[swapFieldCapacity],
              [swapFieldCapacity]: numericParse(row[swapFieldCapacity], row, swapFieldCapacity),
              [unlimitedField]: row[unlimitedFieldCapacity] === '1',
              [yearField]: row[yearField],
            };
          });

          const years = [];
          const dataByYear = {};
          rows.forEach(row => {
            if (!dataByYear[row[yearField]]) {
              dataByYear[row[yearField]] = [];
              years.push(row[yearField]);
            }
            dataByYear[row[yearField]].push(row);
          });

          years.sort((a, b) => b - a);

          resolve({
            dataByYear,
            years,
          });
        })
        .catch(err => {
          console.error(err);
        });
    });
  }

  //Show last date updated. Month followed by year.
  loadLastUpdatedDate() {
    return new Promise(resolve => {
      const url = `${process.env.PUBLIC_URL}/data/${lastUpdatedFile}.csv`;
      axios.get(url).then(response => {
        const rows = csvParse(response.data);
        const lastUpdatedDate = rows[0].last_updated;
        resolve(
          timeParse('%Y-%m-%d')(lastUpdatedDate).toLocaleDateString('default', {
            month: 'long',
            year: 'numeric',
          })
        );
      });
    }).catch(err => {
      console.error(err);
    });
  }
}

const loaderInstance = new DataLoader();

export {
  arrangementTimeseriesField,
  arrangementTimeseriesFieldFormatted,
  countryField,
  ecbAccessField,
  expirationTimeseriesField,
  expirationTimeseriesFieldFormatted,
  filterRowsByIssuer,
  filterRowsByYear,
  formatIssuerMetadata,
  gdpField,
  gdpYearField,
  getIssuerAggregates,
  getIssuerDataKey,
  imf3YearConditionalField,
  imf3YearUnconditionalField,
  incomeGroupField,
  incomeGroupFieldCapacity,
  incomeGroupSortOrder,
  initialCovidDataState,
  initialLendingDataState,
  issuerAndFacilityField,
  issuerFieldExtended,
  issuerSortOrder,
  loanField,
  permanentString,
  rfa3YearField,
  rfaMembershipField,
  rfaMembershipFieldCapacity,
  sortByIssuer,
  swapFieldCapacity,
  unknownString,
  unlimitedField,
  validIssuers,
};

export default loaderInstance;
