export default function SwapIcon({ width, yOffset }) {
  const iconDimensions = { height: 6, width: 8 }
  const scale = width / iconDimensions.width
  const xOffset = (iconDimensions.width / 2 - 0.5) * scale
  return (
    <g transform={`translate(${-xOffset},${-yOffset}) scale(${scale})`}>
      <path
        clipRule="evenodd"
        d="M0.963344 0.901405L1.7355 2.53518L0.190397 3.67526L2.75183 3.93488L4.08891 5.36673L4.91782 3.89513L7.32958 3.62044L5.34463 2.53771L5.37402 0.880108L3.35583 1.66204L0.963344 0.901405Z"
        fill="#3C3C3C"
        fillRule="evenodd"
        transform={`translate(${-0.5 * scale},${0.75 * scale})`}
      />
      <path
        clipRule="evenodd"
        d="M0.963344 0.901405L1.7355 2.53518L0.190397 3.67526L2.75183 3.93488L4.08891 5.36673L4.91782 3.89513L7.32958 3.62044L5.34463 2.53771L5.37402 0.880108L3.35583 1.66204L0.963344 0.901405Z"
        fill="#F5D311"
        fillRule="evenodd"
      />
    </g>
  )
}
